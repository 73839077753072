
.BrandCard_wrapper{
    display:grid;
    margin: 0 auto;
    grid-gap: 15px;
        .BrandCard{
            display: grid;
            width: 100%;
            height: 100%;
            max-width: 250px;
            max-height: 250px;
            //box shadow
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
            border-radius: 15px;

            .ItemName{
                font-size: 16px;
                font-weight: 600;
                color: #c01f1f;
                text-align: center;
                padding: 1rem;
            }
            .ProductCount{
                font-size: 12px;
                font-weight: 600;
                color: #000000;
                text-align: center;
            }
         //card size
           .ArchCount{
                font-size: 12px;
                font-weight: 600;
                color: #000000;
                text-align: center;
            }
            
        
        
         
        }


}
//media qury
@media screen and (min-width: 768px) {
    .BrandCard_wrapper{
        display:grid;
        grid-template-columns:1fr 1fr;
        grid-gap: 1rem;
    //    background-color: #c01f1f;
       
    }
}
//media qury
@media screen and (min-width: 1024px) {
    .BrandCard_wrapper{
        display:grid;
        grid-template-columns:1fr 1fr 1fr;
        grid-gap: 1rem;
      //  background-color: #1f6ac0;
       
    }
}
//media qury
@media screen and (min-width: 1280px) {
    .BrandCard_wrapper{
        display:grid;
        grid-template-columns:1fr 1fr 1fr 1fr;
        grid-gap: 1rem;
      //  background-color: #1f6ac0;
       
    }
}
//media qury
@media screen and (min-width: 1440px) {
    .BrandCard_wrapper{
        display:grid;
        grid-template-columns:1fr 1fr 1fr 1fr 1fr;
        grid-gap: 1rem;
      //  background-color: #1f6ac0;
       
    }
}
//media qury
@media screen and (min-width: 1600px) {
    .BrandCard_wrapper{
        display:grid;
        grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 1rem;
      //  background-color: #1f6ac0;
       
    }
}
