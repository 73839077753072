.users{
    
    @media screen and (max-width: $mobileMax) {
    }
    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
    }
    @media screen and (min-width: $desktopMin) {
    }

    &-view{
        table{
            td{
                @media screen and (min-width: $tabletMin) {
                    &:nth-of-type(1){
                        width: 20%;
                    }
                    &:nth-of-type(2){
                        width: 30%;
                    }
                    &:nth-of-type(3){
                        width: 20%;
                    }
                    &:nth-of-type(4){
                        width: 15%;
                    }
                }
            }
        }
    }

    &-edit{
        &-form{
            position: relative;

            &-contact{
                margin-top: 30px;
            }

            #edit-role-form{
                margin-bottom: 50px;

                .inline-btns{
                    justify-content: flex-start;
                }

                select,
                input{
                    //width: max-content;

                    @media screen and (max-width: $mobileMax) {
                        width: 100%;
                    }
                }
            }
        }
    }
}