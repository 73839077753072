.header{
  /*  display: grid;
    grid-template-columns: 1fr 1fr  1fr ;
    grid-template-rows: minmax(15px, 1fr) minmax(15px, 1fr) ;
    grid-template-areas: 
    "main main main"
    "search_ search_ search_"
    ;*/

   // width: 100%;

  background-color: $Background_Light;
     // background-color: transparent;
    box-shadow: $shadow;

    @media screen and (max-width: $mobileMax) {
        padding-right: 25px;
    }
    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
        padding-right: 35px;
    }
    @media screen and (min-width: $desktopMin) {
        padding-right: 50px;
    }

    &-logo{
        object-fit: contain;

        width: 75px;

        @media screen and (max-width: $mobileMax) {
        }
        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
        }
        @media screen and (min-width: $desktopMin) {
        }
    }
.HeaderWrapper{
    width: 100%;

  max-width: 1900px;
  margin: 0 auto;
    display: grid;
    grid-template-columns: minmax(150px, 1fr)  minmax(150px, 1fr) ;
    grid-template-rows: minmax(1fr,150px) minmax(1fr,150px);
    grid-template-areas: 
    "logo   navheader"
    "dropdown  dropdown"
    ;
    
    .navheader{
        display: grid;
        justify-content: end;
        grid-area: navheader;


      }
    .logo{
        grid-area: logo;  
        display: grid;
        //move to left
        justify-content: start;

    }
    .Header_Cart{
        grid-area: Header_Cart;

        background-color: rgb(0, 201, 77);
    }
    .search_{
        background-color: beige;
        grid-area: search_;
    }
    .dropdown{
        margin:  0 auto;
        grid-area: dropdown;
    }
}
position: -webkit-sticky;
position: sticky;
top: 0;
 display: grid;
 z-index: 100;
}
.RightNav{
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  .CartCount {
    background-color: red;
    color: white;
    font-size: 0.8rem;
    padding: 0.1rem 0.5rem;
    border-radius: 50%;
    
    top: -0.5rem;
    right: -0.5rem;
    }
}

.header {
    /* your existing header styles */
  }
  
 /* .sticky {
   position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
   /*  additional styles for sticky header */
//}

.dropdown {
    position: relative;
    margin: 0 auto;
      width: 100%;
      display: grid;
      grid-template-columns: minmax(1fr,150px) minmax(1fr,150px) minmax(1fr,150px);
    .dropdown-toggle {
      width: 30%;
      max-width: 150px;
      background-color: #fff;
      color: #333;
      border: 1px solid #ccc;
      padding: 8px 16px;
      font-size: 14px;
      cursor: pointer;
  
      &:hover {
        background-color: #f1f1f1;
      }
    }
  .sectionwrapper{
    display: grid;
    grid-template-columns: 1fr ;
    grid-template-rows: 1fr 1fr 1fr;
    .section{
      display: grid;
      .dropdown-menu {
        width: 100%;
       // top: 100%;
        //left: 0;
        z-index: 1;
        background-color: #f7f7f7;
        border: 1px solid #ccc;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
        min-width: 160px;
        padding: 8px 0;
    
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
    
          li {
            padding: 8px 16px;
            cursor: pointer;
    
            &:hover {
              background-color: #f1f1f1;
            }
          }
        }
      }
    }
    
  }
   
  }
  
  //media queries
  @media screen and (min-width: 600px) {
    .dropdown {
      .sectionwrapper{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr ;
      }
      .dropdown-toggle {
        width: 100%;
      }
  
      .dropdown-menu {
        width: 100%;
      }
    }
  }