.addtocartBtn{
    //background reude opacity from var 
   // background-color: $Primary-Blue-Background;
  background-color: transparent;
  color: $Primary-Blue;
   //  background-color: $Primary-Blue;
 //   color: rgb(0, 0, 0);
 //   border: 2px solid $Primary-Blue;
    border-radius: 5px;
    width: 90%;
   // margin: 10px auto;
    padding: 5px;
    &:hover{
        background-color: $Primary-Blue;
        color: white;
    }
}