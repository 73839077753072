.barcode-prices{
    display: flex;
    flex-direction: column;

    margin-bottom: 15px;

    &-container{

    }

    &-price{
        display: grid;
        grid-template-columns: repeat(5, 2fr) 1fr;

        padding: 10px 0;
        border-top: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;

        &-sub{
            p{
                line-height: 100%;
    
                &:first-of-type{
                    font-size: 80%;
                    margin-bottom: 10px;
                }
                &:last-of-type{
                    font-weight: 500;
                }
            }
            button{
                display: inline-block;
                width: max-content;
                padding: 10px 10px 10px 12.5px;
                border-radius: 10px;
    
                background-color: $lightest-grey;
    
                svg{
                    fill: $Primary;
                }
            }

            &:last-of-type{
                text-align: end;
            }
        }
    }
}