


.productArch{
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
 //   border: 1px solid #359c8d;
  margin: 5px 5px;
font-size: 11px;
padding: 15px;
}
.fileuploadmodal{
  grid-area: fileuploadmodal;


}
.iamgeupload{
  grid-area: iamgeupload;
  button{
    font-size: 9px;

  }

}
.title{
  //  border: 1px solid rgb(84, 65, 255);
   // background-color: rgb(97, 71, 216);
   //background-color: #359c8d;
  //main color  background-color: #4682b4;
  background-color: #0a6887;
  // background-color: $Primary-Blue;
      padding: 5px;
        border-radius: 5px;
        text-align: center;
        color: white;
        font-weight: 600;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 0;
        padding: 10px;
        display: grid;
        grid-template-columns:1fr 1fr 1fr 1fr;
        grid-row: 1fr 1fr;
        
grid-template-areas: 
"titlename ArcCode OEM_Code iamgeupload"
"fileuploadmodal fileuploadmodal fileuploadmodal fileuploadmodal"
;
.ArcCode{
  grid-area: ArcCode;
}
.OEM_Code{
  grid-area: OEM_Code;
}
        .iamgeupload{
          button{
            color: white;
            font-size: 15px;
            padding: 0;
            margin: 0;
          }
        }
}

.products{
   display: grid;

}
.product{

font-weight: 600;
    width: 90%;
    display: grid;
    //center items vertically
      align-items: center;
    //border: 1px solid #359c8d;
 //shadow
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    margin: 10px auto;

    grid-template-rows:  1fr 1fr minmax(1fr,100px) auto 1fr  ;
    grid-template-columns:  1fr 1fr ;
    border: 2px solid #25D442;
    background-color: #F3FFF5;
    font-size: 10px;

    &.onunits{
    border: 2px solid #292929;
    border: none;
    background-color: rgb(226, 226, 226);

     }
     &.unmoved{
      border: 2px solid #FF0000;
      background-color: #FFF8F8;

     }
    &:hover{
transition: .5s;
    background-color: #25d442a2;
   
&.onunits{
   background-color: #2929296c;
 
}
      &.unmoved{
            background-color: #ff00005d;
         
      }

   }
     a{
transition: .8s;

      color: #359c8d;
color: black;

     }
     text-align: center;

  grid-template-areas: 
     "productname productname" 
     "suppliercode Acode"
     "Model brand_Name"
     "Unit_Price quantity"
     "Supplier Supplier"
     "addtocart addtocart"
     ;
 

/*
grid-template-areas: 
"suppliercode "
"Acode "
"productname " 
" brand_Name"
"Unit_Price "
"quantity "
"Supplier "
;*/

     .suppliercode{
   //   background-color: rgba(182, 124, 15, 0.116);
      text-align: left;
      grid-area: suppliercode;
     }
     .productname{
      text-align: left;

      grid-area: productname;
  //    background-color: rgba(190, 98, 11, 0.158);
     }
     .Acode{
      grid-area: Acode;
      text-align: right;
  //    background-color: rgba(15, 182, 85, 0.11);
     }
   
     .Model{
      grid-area: Model;
   //   background-color: rgba(18, 15, 182, 0.233);
     }
     .brand_Name{
      display: grid;
      justify-content: end;
      text-align: right;
      grid-area: brand_Name;
 //     background-color: rgba(182, 15, 15, 0.253);
     }
     .quantity{
      grid-area: quantity; 
      display: grid;
      justify-content: end;
   //   background-color: rgba(15, 182, 85, 0.274);
     }
     .Unit_Price{
      text-align: left;
      grid-area: Unit_Price;
   //   background-color: rgba(182, 124, 15, 0.199);
     }
     .Supplier{
      grid-area: Supplier;
  //    background-color: rgba(95, 63, 4, 0.137);
     }
     .addtocart{
      grid-area: addtocart;
     }
     .Flagitem{
      grid-area: Flagitem;
     }
     .BillingGroup{
      grid-area: BillingGroup;
     }
 }

//mediaquery for productrowonarch
@media screen and (min-width: 768px) {
  .title{
    font-size: 15px;
  }
    .product{
      font-size: 15px;
    display: grid;
    width: 100%;
    grid-template-rows:  1fr ;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* set a fixed width of 200px for each column */
      grid-template-rows:  minmax( 1fr,15px)  minmax( 1fr,15px) minmax( 1fr,15px);
        grid-template-areas: 
        "suppliercode productname productname Flagitem Acode  Model brand_Name Unit_Price quantity Supplier BillingGroup addtocart"
        "suppliercode productname productname Flagitem Acode  Model brand_Name Unit_Price quantity Supplier BillingGroup addtocart"
        "suppliercode productname productname Flagitem Acode  Model brand_Name Unit_Price quantity Supplier BillingGroup addtocart"
        ;
        .Unit_Price{
         text-align: right;
         grid-area: Unit_Price;
      //   background-color: rgba(182, 124, 15, 0.199);
        }
        .Acode{
         grid-area: Acode;
         text-align: center;
     //    background-color: rgba(15, 182, 85, 0.11);
        }
    }
}

.list {
    display: flex;
    flex-direction: column;
  }
  
  .item {
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 8px;
    background-color: #fff;
    cursor: grab;
  }
  
  .item:hover {
    background-color: #f4f4f4;
  }
  
  .item:active {
    cursor: grabbing;
    background-color: #eee;
  }
  .brand_container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
 }
 .productbrandselectlist{
  width: 100%;
  margin: 0 auto;
  height: 150px;
  overflow-y: auto;
  overflow-x:hidden;

  .productbrandselect{
    margin: 15px 0 ;
    border: 1px solid $Primary;
    border-radius: 5px;
    padding:  5px 5px 5px 5px ;
    width: 80%;
    font-size: 12px;
    font-weight: 600;
    color: $Primary;
    cursor: pointer;
   text-align: left;
    list-style-type: none;
    &:hover{
      background-color: $Primary;
      color: white;
    }
  }
 }