.register{
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    box-sizing: border-box;

    padding: 0 30px;

    background-color: $Background_Light;

    @media screen and (max-width: $mobileMax) {
    }
    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
    }
    @media screen and (min-width: $desktopMin) {
        padding: 0;
    }

    h1{
        line-height: 100%;
        color: $main-grey;

        margin-bottom: 40px;

        @media screen and (max-width: $mobileMax) {
        }
        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
        }
        @media screen and (min-width: $desktopMin) {
        }
    }

    &-progressbar{
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;

        margin-bottom: 40px;

        counter-reset: step;

        @media screen and (max-width: $mobileMax) {
        }
        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
        }
        @media screen and (min-width: $desktopMin) {
            width: 560px;
        }

        li{
            position: relative;
            text-align: center;
            
            list-style: none;

            color: white;
            
            margin: 0 10px;

            @media screen and (max-width: $mobileMax) {
            }
            @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
            }
            @media screen and (min-width: $desktopMin) {
                margin: 0 15px;

                font-size: 20px;
            }

            &:nth-of-type(2){
                &::after{
                    content: "";
                    
                    position: absolute;
                    left: -30px;
                    top: 50%;
                    transform: translateY(-100%);
                    z-index: -1;
    
                    width: 30px;
                    height: 3px;
    
                    background: $unfocused-grey;

                    transition: .2s ease-in-out;
                }

                &.active{
                    &::after{
                        background: $Primary;
                    }
                }
            }

            &::before{
                background-color: $Primary;

                content: counter(step);
                counter-increment: step;

                border: 2px solid $unfocused-grey;
                border-radius: 100%;
                
                color: $unfocused-grey;
                background: white;

                display: block;

                transition: .2s ease-in-out;

                width: 30px;
                line-height: 30px;

                @media screen and (max-width: $mobileMax) {
                }
                @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                }
                @media screen and (min-width: $desktopMin) {
                    width: 45px;
                    line-height: 45px;
                }
            }

            &.active{
                &::before{
                    border: 2px solid $Primary;

                    color: white;
                    background: $Primary;
                }
            }
        }
    }

    &-form{
        position: relative;
        width: 100%;

        @media screen and (max-width: $mobileMax) {
            margin-bottom: 75px;
        }
        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
            margin-bottom: 100px;
        }
        @media screen and (min-width: $desktopMin) {
            margin-bottom: 125px;
        }

        &-wrapper{
            @media screen and (max-width: $mobileMax) {
                width: 100%;
            }
            @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                width: 85%;
            }
            @media screen and (min-width: $desktopMin) {
                width: 560px;
            }
        }

        .inline-btns{
            input[type=submit],
            input[type=button]{
                color: white;

                width: 50%;

                background-color: $Primary;
                border: 1px solid $Primary;

                margin-top: 75px;

                @media screen and (max-width: $mobileMax) {
                    font-size: 16px;
        
                    padding: 10px 0;
                    margin-top: 15px;
                }
                @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                    font-size: 18px;
        
                    padding: 10px 0;
                }
                @media screen and (min-width: $desktopMin) {
                    font-size: 20px;
        
                    padding: 17.5px 0;
                    border-radius: 20px;
                }

                &#register-prev{
                    color: $Primary;
    
                    background-color: white;
                    border: 1px solid transparent;

                    margin-right: 30px;

                    &:hover{
                        border: 1px solid $Primary;
                    }

                    @media screen and (max-width: $mobileMax) {
                        margin-right: 0;
                    }
                    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                    }
                    @media screen and (min-width: $desktopMin) {
                    }
                }
            }
        }

        fieldset{
            border-radius: 30px;

            padding: 30px;

            box-shadow: $shadow;
            background-color: white;

            box-sizing: border-box;

            position: absolute;

            transition: all .5s ease-in-out;

            width: 100%;

            @media screen and (max-width: $mobileMax) {
            }
            @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
            }
            @media screen and (min-width: $desktopMin) {
                width: 560px;

                padding: 60px;
            }

            &.inactive{
                visibility: hidden;
                opacity: 0%;

                left: 100%;

                @media screen and (min-width: $desktopMin) {
                    left: 50%;
                    transform: scale(.75);
                }
            }
            &.active{
                visibility: visible;
                opacity: 100%;

                left: 0%;
            }
            &.completed{
                opacity: 0%;

                @media screen and (min-width: $desktopMin) {
                    transform: scale(.75);
                }
            }

            h2{
                text-align: center;
                margin-bottom: 30px;
                color: $main-grey;

                @media screen and (max-width: $mobileMax) {
                }
                @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                }
                @media screen and (min-width: $desktopMin) {
                    margin-bottom: 50px;
                }
            }

            &:nth-of-type(1){
                h2{
                    margin-bottom: 15px;
    
                    @media screen and (max-width: $mobileMax) {
                    }
                    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                    }
                    @media screen and (min-width: $desktopMin) {
                        margin-bottom: 25px;
                    }
                }
            }

            .floating-input{
                margin-bottom: 30px;

                @media screen and (max-width: $mobileMax) {
                    margin-bottom: 20px;
                }
                @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                }
                @media screen and (min-width: $desktopMin) {
                }
            }
        }        
    }
}