// Cart container
.cartContainer {
    background-color: aqua;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  
    @media (min-width: 768px) {
      margin: 2rem;
    }
  
    @media (min-width: 1024px) {
      margin: 3rem;
    }
  }
  
  // Table head
  .tableHead {
    display: none;
  
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 1rem;
      font-weight: bold;
      background-color: #f2f2f2;
      margin-bottom: 1rem;
    }
  }
  
  // Table body
  .tableBody {
    display: flex;
    flex-direction: column;
    width: 100%;
  
    @media (min-width: 768px) {
      overflow-x: auto;
    }
  }
  
  // Table rows
  .tableBody > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
  
    @media (min-width: 768px) {
      margin-bottom: 1rem;
    }
  }
  