/* PatientForm.scss */

/* Styling for the form container */
.form-container {
    max-width: 400px; /* Adjust as needed */
    margin: 0 auto;
  }
  
  /* Styling for the form labels */
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  /* Styling for the input fields */
  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Styling for the submit button */
  button[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  /* Styling for the Doctor information */
  .doctor-info {
    margin: 20px 0;
    font-weight: bold;
  }
  
  /* Styling for the form title */
  .form-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .radio-group {
    display: inline;
    background-color: #0056b3;
    
  }
  
  input[type="radio"] {
    margin-right: 5px; /* Adjust as needed to control spacing between radio buttons */
  }

  .treatment_log_Wrapper{
   // background-color: #0056b3;

    display: grid;
    grid-template-columns: 3fr 2fr;

    .logs{
     max-width:700px;

     // background-color: #ccc;
    }
    .form-container{

    }
  }