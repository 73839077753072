.inbound-view{
    .inline-btns{
        justify-content: flex-start !important;
    }
}

.inbound-details{

    &-top{
        margin: 30px 0;

        p{
            font-size: 110%;

            strong{
                font-weight: 500;
            }

            &:not(:last-of-type){
                margin-bottom: 10px;
            }
        }
    }

    &-main{
        @media screen and (max-width: $tabletMax) {
        }
        @media screen and (min-width: $desktopMin) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 30px;
        }
    }

    &-transactions{
        @media screen and (max-width: $tabletMax) {
            margin-bottom: 30px;
        }

        &-add{
            margin: 30px 0 10px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            width: 100%;
            padding: 25px 50px;

            background-color: $Background_Light;
            border-radius: 20px;
            border: 1px solid $unfocused-grey;
            color: $main-grey;

            svg{
                fill: $main-grey;

                height: 20px;
                margin-right: 10px;
            }

            &:disabled{
                svg{
                    fill: white;
                }
            }
        }

        &-item{
            position: relative;
            width: 100%;
            margin: 10px 0;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            width: 100%;
            padding: 25px;

            box-sizing: border-box;
            border-radius: 20px;
            border: 1px solid $main-grey;
            color: $main-grey;

            p{
                strong{
                    font-weight: 600;
                }

                &:not(:last-of-type){
                    margin-bottom: 5px;
                }
            }

            button{
                position: absolute;
                top: 0%;
                right: 0%;
                transform: translate(-75%, 55%);

                padding: 0;

                svg{
                    height: 20px;
                    width: auto;

                    fill: $danger;
                }

                &:hover{
                    opacity: .9;
                }
            }
        }
    }

    &-cart{
        
        &-container{
            overflow-x: auto;
        }
        table{
            width: 100%;
            border-collapse: collapse;
        
            overflow: hidden;

            th{
                border-bottom: 1px solid $main-grey;
            }

            th{
                padding: 10px 10px;
            }
            td{
                padding: 7.5px 10px;
            }
        }
    }
}

.receipt-modal{
    width: 80%;
    height: 80%;
}

.receipt-iframe{
    height: max-content;
    width: 3in;
    max-width: 3in;
    
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;

    &-wrapper{
        position: fixed;
        top: 0%;
        left: 0%;
        z-index: 2;

        display: flex;
        justify-content: center;
        align-items: center;
        
        height: 100%;
        width: 100%;

        background-color: rgba(white, .95);
    }

    &-container{
        overflow: hidden auto;

        height: max-content;
        max-height: 75%;
        width: 3in;
        max-width: 3in;

        box-shadow: $shadow;
    }

    &-close{
        position: absolute;
        top: 2.5%;
        right: 0%;
    }

    &-printbtn{
        position: absolute;
        top: 2.5%;
        left: 50%;
        translate: -50% 0;

        font-size: 125%;

        background-color: $Primary;
        color: white;
        text-transform: uppercase;
    }
}