/*.imagerow{
    background-color: aqua;

display: grid;


.image-wrapper{
    background-color: aquamarine;
    display: grid;

        .image{
            display: grid;
            background-color: bisque;

            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            img{
            max-width:150px;

            }
        }

    }
}*/

.imagerow {
    .image-wrapper {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }

padding: 15px;      
      .image {
//        margin: 0 20px;
        margin: 0 auto;

        flex: 0 0 auto;
        img {
          border: 2px solid #359c8d;
            border-radius: 5px;
            box-shadow: $shadow;

            max-width:50px;
        }
      }
    }
  }
  
  .imagerowmodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    &-content{
      padding: 10px;
     /* width: 80%;
      height: 80%;*/
      max-width: 500px;
      max-height: 500px;
        display: grid;
        place-items: center;
      //  background-color: rgb(41, 209, 209);
        background-color: rgb(255, 255, 255);
        border-radius: 15px;
     /*   width: 80%;
        height: 80%;*/
     
        .modal-image{
         margin: 0 auto;
            img{
              max-width: 450px;
              max-height: 450px;
            }
       
      }
       
    }
    .Title{
   font-size: 30px;
   color:$Primary;
    }
  }
  //media queries
  @media screen and (max-width: 768px) {
    .Title{
   font-size: 15px;
  }
    .imagerowmodal{
      &-content{
        margin: 0 auto;
      padding: 10px;
      width: 80%;
      height: 80%;

        .modal-image{
          img{
            max-width: 250px;
            max-height: 250px;
          }
        }
      }
    }
    .imagerow {
     // background-color: black;
      .image-wrapper {
        .image {
          img {
            max-width: 100px;
          }
        }
      }
    }
  }
  .remove-button:hover {
    background-color: #cc0000;
  }
  .remove-button {
    background-color: #ff1a1a;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }