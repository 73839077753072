@import './Background_Animation';
.wrapper_Dashboard_Director{
    display: grid;

    grid-template-columns: 1fr 5fr;
    grid-template-areas: 
    "DirectorQuickAccess DirectorDashboard"

    ;
    background-color: rgb(255, 255, 255);

}
.DirectorQuickAccess{
    grid-area: DirectorQuickAccess;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;

    .SalesSummary{
        position: relative;

text-align: center;
        &:hover {
           // transform: scale(1.05);
border: $Primary-Theme-Green 2px solid;
            transition-duration: 150ms;
            box-shadow: 0 5px 20px 5px #00000044;
           // color:$Primary-Theme-Green;
          }
        max-height: 150px;
        width: 80%;
        height: 100%;
        max-width: 300px;
        margin: 0 auto;
        border-radius: 15px;

       background-color: rgb(248, 248, 248);
        border: 2px $Primary-Theme-Green solid;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;

        grid-template-areas: 
        "h4 h4 h4"
        "h4 h4 h4"
        "totalOrders TotalSales Net"
        ;
        margin-top: 10px;
        h4{
            grid-area: h4;
            display: grid;
            place-items: center;
        }
        .totalOrders{
            border-top: 2px $unfocused-grey solid;        
            
            &:hover {
                // transform: scale(1.05);
     border-top: $Primary-Theme-Green 2px solid;
     color: $Primary-Theme-Green;
           
               }
            grid-area: totalOrders;
            display: grid;
            place-items: center;
            p{
                font-size: 80%;
                margin-bottom: 5px;
            }
        }
        .TotalSales{
            border-top: 2px $unfocused-grey solid;        
            
            &:hover {
                // transform: scale(1.05);
     border-top: $Primary-Theme-Green 2px solid;
           color: $Primary-Theme-Green;
               }
            grid-area: TotalSales;
            display: grid;
            place-items: center;
            p{
                font-size: 80%;
                margin-bottom: 5px;
            }
        }
        .Net{
            &:hover {
                // transform: scale(1.05);
     border-top: $Primary-Theme-Green 2px solid;
     color: $Primary-Theme-Green;
           
               }
            border-top: 2px $unfocused-grey solid;
            grid-area: Net;
            display: grid;
            place-items: center;
            p{
                font-size: 80%;
                margin-bottom: 5px;
            }
        }
    }
}
.DirectorDashboard{
    grid-area: DirectorDashboard;
    
    width: 100%;
    height: 100%;
    max-width: 1920px;
    margin: 0 auto;
    display: grid;

    grid-template-columns: 1fr 1fr 1fr  1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

    grid-template-areas: 
    "three three three  three"
    "three three three  three"
    //"two two two  "
    "two two two two"
    " four four four four"
    "six six six six"
    "five five five five"
    ;
    
    grid-gap: 20px;
}

.DirectorDashboard_Card{
    border-radius: 20px;
    height: 250px;
    transition: .2s ease-in-out ;
    box-shadow: $shadow;
    padding: 15px;
    display: grid;
    place-items: center;
    &:hover{
   // scale: 1.01;
    
}
    &.one{
        display: grid;
       height: 80%;

        background-color: rgb(255, 255, 255);
        grid-area: one;


    }
    
    &.two{

        height: 100%;
        width: 100%;
       max-height: 350px;

        margin: 15px;
        padding: 0;

        grid-area: two;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: minmax(25px, 50px) 1fr 1fr;
        grid-template-areas:
        "Title Title"
        "Content Content"
        "Content Content";
        .Title{
            border-radius: 20px 20px 0 0;
            border-bottom: 3px solid $Primary-Theme-Green;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.75);


            grid-area: Title;
            display: grid;
            text-align: center;
            place-items: center;
            p{
                font-size: 1rem;
                font-weight: 600;
            }
        }
a{
    color: rgb(17, 17, 17);
}
background: rgba(255, 255, 255, 0.46);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
        .Content{
          
        border-radius:  0 0 20px 20px;
        //background-color: rgb(255, 255, 255);
            overflow-y: auto;
            width: 100%;
            height: 100%;
            grid-area: Content;
            grid-template-rows: 1fr  1fr 1fr 1fr 1fr;
            grid-template-columns: 1fr ;
                margin: 0 auto;
                .Order_dashboard{
               
            .OrderDate{
            }
            font-size: 12px;
            font-weight: 600;
                    margin: 0 auto;
                    display: grid;
                    margin: 5px;
                    border-radius: 5px;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    grid-template-rows: 1fr;
                    text-align: left;
                    transition: .2s ease-in-out ;
                    &:hover{
                    box-shadow: $shadow;
                   background-color: $Primary-Theme-Green;
                   border-radius: 0;
                   color: rgb(241, 241, 241);

                   
                    cursor: pointer;
                    }
                }
            h1{
                font-size: 2rem;
                font-weight: 600;
            }
        }
    }
    
    &.three{
        height: 100%;
       // background-color: rgb(255, 255, 255);
       background: rgba(255, 255, 255, 0.46);
       border-radius: 16px;
       box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
       backdrop-filter: blur(5px);
       -webkit-backdrop-filter: blur(5px);
       border: 1px solid rgba(255, 255, 255, 0.3);

        grid-area: three;
         margin: 15px ;
        padding: 0;

        width: 100%;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: minmax(25px, 50px) 1fr 1fr;
        grid-template-areas:
        "Title Title"
        "Content Content"
        "Content Content";
        a{
            color: rgb(17, 17, 17);

        }
        .Title{
            border-radius: 20px 20px 0 0;
                border-bottom: 3px solid $Primary-Theme-Green;
    
                width: 100%;
                height: 100%;
               // background-color: rgba(255, 255, 255, 0.75);
    
    
                grid-area: Title;
                display: grid;
                text-align: center;
                place-items: center;
                p{
                    font-size: 1rem;
                    font-weight: 600;
                }
            }

        .Content{
            border-radius:  0 0 20px 20px;
    //        background-color: rgb(255, 255, 255);
                overflow-y: auto;
                width: 100%;
                height: 100%;
                grid-area: Content;
                grid-template-rows: 1fr  1fr 1fr 1fr 1fr;
                grid-template-columns: 1fr ;
                    margin: 0 auto;
                    .Order_dashboard{
                   
                .OrderDate{
                }
                font-size: 12px;
                font-weight: 600;
                        margin: 0 auto;
                        display: grid;
                        margin: 5px;
                        border-radius: 5px;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        grid-template-rows: 1fr;
                        text-align: left;
                        transition: .2s ease-in-out ;
                       
                        &:hover{
                        box-shadow: $shadow;
                        background-color: $Primary-Theme-Green;
                        cursor: pointer;
                            color: rgb(241, 241, 241);
                        }
                    }
                h1{
                    font-size: 2rem;
                    font-weight: 600;
                }
            }
    }
    &.four{
        grid-area: four;


        margin: 15px;
        padding: 0;


        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: minmax(25px, 50px) 1fr 1fr;
        grid-template-areas:
        "Title Title"
        "Content Content"
        "Content Content";
        .Title{
        border-radius: 20px 20px 0 0;

            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.75);


            grid-area: Title;
            display: grid;
            text-align: center;
            place-items: center;
            p{
                font-size: 1rem;
                font-weight: 600;
            }
        }
a{
    color: rgb(17, 17, 17);

}
background: rgba(255, 255, 255, 0.46);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
        .Content{
          
        border-radius:  0 0 20px 20px;
        //background-color: rgb(255, 255, 255);
            overflow-y: auto;
            width: 100%;
            height: 100%;
            grid-area: Content;
            grid-template-rows: 1fr  1fr 1fr 1fr 1fr;
            grid-template-columns: 1fr ;
                margin: 0 auto;
                .Order_dashboard{
               
         
            font-size: 12px;
            font-weight: 600;
                    margin: 0 auto;
                    display: grid;
                    margin: 5px;
                    border-radius: 5px;
                    grid-template-columns: 2fr 1fr 1fr  1fr;
                    grid-template-rows: 1fr;
                    text-align: left;
                    transition: .2s ease-in-out ;
                    &:hover{
                    box-shadow: $shadow;
                   background-color: $Primary-Theme-Green;
                   border-radius: 0;
                   color: rgb(241, 241, 241);

                   
                    cursor: pointer;
                    }
                }
            h1{
                font-size: 2rem;
                font-weight: 600;
            }
        }
    }
 
    &.five{
        background-color: rgb(255, 255, 255);

        grid-area: five;
    }
    
    &.six{
        background-color: rgb(255, 255, 255);

        grid-area: six;
    }
}

//media qury for screens bigger than 700 px
