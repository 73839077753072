.Doctor_Logs{
display: grid;
border:  1px solid green;
border-radius: 15px;

margin: 15px auto;
padding: 15px;

max-width:700px;


}