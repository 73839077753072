.Modal_parent{
    background-color: #ff0d0d;   
    width:100%;

}
  
.modal-content input {
    font-size: 16px;
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
/*
.modal-content button {
    font-size: 18px;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }*/
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
    z-index: 999;
  }

  
  .modal-content {

    background-color: #ffffff;
    margin: 0 auto;
height: 95%;
    width:100%;
    display: grid;
    /* ... */
    box-shadow: $shadow;
    border-radius: 10px;
    border: 2px solid $Primary-Royal-Blue;
grid-gap: 15px;
padding: 10px;
    grid-template-columns: 1fr 1fr;
   grid-template-rows: minmax(15px, .5fr)  auto auto auto auto auto auto;
    grid-template-areas: 
    "Productincartmodal Productincartmodal"
    "Quantity Quantity"
    "discount discount"
    "Total Total"
    "note note"
    "paymentmode paymentmode"
    "creditterms creditterms"
    "addtocart addtocart"
    ;
    .Productincartmodal{
    display: grid;
        grid-area: Productincartmodal;
    }
    .Quantity{
        grid-area: Quantity;    
        width: 90%;
        margin: 0 auto;
    display: grid;
    place-content: center;
   // background-color: #a9e4cb;
    }
    .discount{
    display: grid;
    width: 90%;
margin: 0 auto;
    place-content: center;
 // background-color: #e2a9e4;
        grid-area: discount;
    }
    .paymentmode{
    display: grid;
  //  background-color: #e4a9b8;
        grid-area: paymentmode;
    }
    .creditterms{
    display: grid;
  //  background-color: #c5e4a9;
        grid-area: creditterms;
    }
    .note{
    display: grid;
  //  background-color: #e4e0a9;
        grid-area: note;
        width:100%;

       
    }
    .addtocart{
    display: grid;
        grid-area: addtocart;
            /*button{
    //width:90%;

                background-color: #359c8d;
                color: rgb(255, 255, 255);
            }*/
    }
    .closecartmodalbtn{
                display: grid;
                justify-self: end;


    place-content: center;
    grid-area: closecartmodalbtn;


        button{
            background-color: red;
            color: rgb(255, 255, 255);
        }
    }
    .Total{
        margin: 0 auto;
    display: grid;
        grid-area: Total;
    }
  }

  
  @media (min-width: 767px) {
    .modal-content {
        width: 90%;
      /* ... */
      display: grid;
      height: auto;
      grid-template-columns: minmax(15px, 1fr) minmax(15px, 1fr) minmax(15px, 1fr) minmax(15px, 1fr);
      grid-template-rows:50px 1fr 1fr 1fr  ;
grid-gap: 15px;
padding: 15px;
      grid-template-areas: 
      "Productincartmodal Productincartmodal    Productincartmodal Productincartmodal"
      "Quantity discount note note"
      "paymentmode   creditterms .  Total "
     
      "addtocart addtocart addtocart addtocart"
      ;
     
      .Productincartmodal{

width: 100%;
height: 10%;


      }
      .closecartmodalbtn{
        justify-self: start;
        
      }
    }
  
    .modal-content input {
      font-size: 20px;
      padding: 20px;
      margin-bottom: 20px;
    }
  
    .modal-content button {
      font-size: 24px;
      padding: 20px;
    }
  }
  
  .paymentmode {
    display: flex;
    gap: 5px;
  }
  
  .paymentmode button {
    flex: 1;
    border: none;
    padding: 10px;
    font-size: 1em;
    color: #ffffff;
    background-color: #bbbbbb;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .paymentmode button.selected {
    background-color: #007bff;
  }
  