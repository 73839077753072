.invoicetable{
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    margin: 0 auto;
    thead{
        background-color: #f5f5f5;
        tr{
            th{
                padding: 8px;
                line-height: 1.42857143;
                vertical-align: top;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                text-align: left;
            }
        }
    }
    tbody{
        tr{
            td{
                padding: 8px;
                line-height: 1.42857143;
                vertical-align: top;
                border-top: 1px solid #ddd;
                text-align: left;
            }
        }
    }
    tfoot{
        tr{
            td{
                padding: 8px;
                line-height: 1.42857143;
                vertical-align: top;
                border-top: 1px solid #ddd;
                text-align: left;
            }
        }
    }
}