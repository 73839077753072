.report{
    margin: 0 auto;
    width: 100%;
        
    @media screen and (max-width: $tabletMax) {
        margin-bottom: 50px;
    }
    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
    }
    @media screen and (min-width: $desktopMin) {
        width: 100%;
    }
    
    &-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100%;
    }

    &-large{
        width: 100%;
        min-height: 500px;
        box-sizing: border-box;
        padding: 20px 30px;
        border-radius: 20px;
        overflow-x: auto;

        background-color: white;
        box-shadow: $shadow;

        @media screen and (max-width: $mobileMax) {
            min-height: 300px;
            padding: 10px;
        }
        @media screen and (min-width: $desktopMin) {
            min-height: 500px;
        }

        &:last-of-type{
            margin-bottom: 100px;
        }

        &.pie{
            padding: 30px;

            @media screen and (max-width: $mobileMax) {
                min-height: 200px;
            }
        }
    }

    &-small{
        &-wrapper{
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            column-gap: 50px;
            row-gap: 35px;
            margin-bottom: 50px;
            width: 100%;
            
            @media screen and (max-width: $mobileMax) {
                margin-bottom: 20px;
                column-gap: 20px;
                row-gap: 20px;
            }
            @media screen and (min-width: $desktopMin) {
                grid-auto-columns: minmax(0, 1fr);
                grid-auto-flow: column;
                width: 100%;
            }
        }

        display: grid;
        grid-template-columns: 1fr 4fr;
        column-gap: 10px;
        align-items: center;

        background-color: white;
        box-shadow: $shadow;

        box-sizing: border-box;
        border-radius: 15px;
        padding: 20px 25px;

        @media screen and (max-width: $mobileMax) {
            display: block;
        }

        svg{
            height: 35px;
            width: auto;
            margin-right: 10px;

            fill: $Primary;

            @media screen and (max-width: $mobileMax) {
                height: 25px;

                display: none;
            }
        }
        &-text{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            text-align: end;
            height: 100%;
        }
        &-label{
            font-size: 90%;
            opacity: 80%;

            margin-bottom: 10px;
        }
        &-value{
            font-weight: 500;
            font-size: 120%;
            line-height: 100%;
        }

        &.outbound{
            svg{
                fill: $danger;
            }
        }
    }

    &.home{
        @media screen and (min-width: $desktopMin) {
            width: 90%;
        }

        .report-small{
            &-wrapper{
                @media screen and (min-width: $desktopMin) {
                    //grid-template-columns: repeat(4, 1fr);
                }
            }
        }
    }
}

.report-view{
    
}