.Product_datachart{
    width: 50%;
}

.Product_Stock_Info {
   display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  table {
    border-collapse: collapse;
    width:80%;
  }
  
  thead {
    background-color: #ddd;
  }
  
  th,
  td {
    text-align: left;
    padding: 8px;
  }
  /*
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }*/
  
  tr:hover {
    background-color: #ddd;
  }
  
  @media (max-width: 600px) {
    table {
      font-size: 12px;
    }
  }
  
  .in-stock {
    background-color: #0a68877a;
    color: black;
  }
  
  .out-stock {
    background-color:#25d44233;
    color: black;

  }