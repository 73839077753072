.login{
    width: 100%;

    display: grid;
   /* flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    */
place-content: center;
align-items: center;
    padding: 0 30px;
    
    position: absolute;
 //   top: 50%;
 //   transform: translateY(-150%);

    background-color: $Background_Light;

    @media screen and (max-width: $mobileMax) {
    }
    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
    }
    @media screen and (min-width: $desktopMin) {
        padding: 0;
    }

    h1{
        line-height: 100%;
        color: $Primary;

        margin-bottom: 40px;

        @media screen and (max-width: $mobileMax) {
        }
        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
        }
        @media screen and (min-width: $desktopMin) {
        }
    }

    &-form{
        position: relative;
        width: 100%;

        @media screen and (max-width: $mobileMax) {
            margin-bottom: 75px;
        }
        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
            margin-bottom: 100px;
        }
        @media screen and (min-width: $desktopMin) {
            margin-bottom: 125px;
        }

        &-wrapper{
            @media screen and (max-width: $mobileMax) {
                width: 100%;
            }
            @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                width: 75%;
            }
            @media screen and (min-width: $desktopMin) {
                width: 450px;
            }
        }

        .inline-btns{
            input[type=submit],
            input[type=button]{
                color: white;

                width: 50%;

                background-color: $Primary;
                border: 1px solid $Primary;

                margin-top: 30px;

                @media screen and (max-width: $mobileMax) {
                    font-size: 16px;
        
                    padding: 10px 0;
                    margin-top: 15px;
                }
                @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                    font-size: 18px;
        
                    padding: 10px 0;
                }
                @media screen and (min-width: $desktopMin) {
                    font-size: 20px;
        
                    padding: 18px 0;
                }
            }
        }

        fieldset{
            border-radius: 25px;

            box-shadow: $shadow;
            background-color: white;

            box-sizing: border-box;

            position: absolute;

            transition: all .5s ease-in-out;

            width: 100%;

            @media screen and (max-width: $mobileMax) {
                padding: 30px;
            }
            @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                padding: 30px 45px;
            }
            @media screen and (min-width: $desktopMin) {
                padding: 40px;
            }

            .floating-input{
                margin-bottom: 30px;

                @media screen and (max-width: $mobileMax) {
                    margin-bottom: 20px;
                }
            }
        }   
        
        &-forgot{
            display: block;
            width: 100%;
            text-align: center;

            margin-bottom: 10px;

            font-size: 90%;
            font-weight: 500;
            text-decoration: underline;
            color: blue !important;
        }
    }
}

.reset,
.forgot{
    width: 100%;

    display: grid;
  /*  flex-direction: column;
    justify-content: flex-start;*/
    align-items: center;

   // box-sizing: border-box;
place-content: center;
    padding: 0 30px;
    
    position: absolute;
   /* top: 50%;
    transform: translateY(-150%);
*/
    background-color: $Background_Light;

    @media screen and (max-width: $mobileMax) {
    }
    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
    }
    @media screen and (min-width: $desktopMin) {
        padding: 0;
    }

    h1{
        line-height: 100%;
        color: $Primary;

        margin-bottom: 40px;

        @media screen and (max-width: $mobileMax) {
        }
        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
        }
        @media screen and (min-width: $desktopMin) {
        }
    }

    &-form{
        position: relative;
        width: 100%;

        @media screen and (max-width: $mobileMax) {
            margin-bottom: 75px;
        }
        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
            margin-bottom: 100px;
        }
        @media screen and (min-width: $desktopMin) {
            margin-bottom: 125px;
        }

        &-wrapper{
            @media screen and (max-width: $mobileMax) {
                width: 100%;
            }
            @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                width: 75%;
            }
            @media screen and (min-width: $desktopMin) {
                width: 450px;
            }
        }

        .stacked-btns{
            input[type=submit],
            input[type=button]{

                //margin-top: 30px;

                @media screen and (max-width: $mobileMax) {
                    font-size: 16px;
        
                    padding: 10px 0;
                    margin-top: 15px;
                }
                @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                    font-size: 18px;
        
                    padding: 10px 0;
                }
                @media screen and (min-width: $desktopMin) {
                    font-size: 20px;
        
                    padding: 18px 0;
                }
            }
            input[type=submit]{
                color: white;

                border: 1px solid $Primary;
                background-color: $Primary;
            }
            input[type=button]{
                color: $Primary;

                background-color: white;
            }
        }

        fieldset{
            border-radius: 25px;

            box-shadow: $shadow;
            background-color: white;

            box-sizing: border-box;

            position: absolute;

            transition: all .5s ease-in-out;

            width: 100%;

            @media screen and (max-width: $mobileMax) {
                padding: 30px;
            }
            @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                padding: 30px 45px;
            }
            @media screen and (min-width: $desktopMin) {
                padding: 40px;
            }

            .floating-input{
                //margin-bottom: 30px;

                @media screen and (max-width: $mobileMax) {
                    //margin-bottom: 20px;
                }
            }
        }   
        
        a{
            display: block;
            width: 100%;
            text-align: center;

            margin-bottom: 10px;

            font-size: 90%;
            font-weight: 500;
            text-decoration: underline;
            color: blue !important;
        }
    }
}