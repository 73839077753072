@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
html {
  scroll-behavior: smooth;
  font-family: "Poppins";
  font-size: 16px;
}

@media screen and (max-width: 480px) {
  html {
    font-size: 14px;
  }
}

html.noscroll {
  overflow: hidden;
}

body {
  margin: 0 auto;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

#root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
}

.content {
  display: -ms-grid;
  display: grid;
  position: relative;
  background-color: white;
}

.content-child {
  -webkit-animation: page-change .5s ease-in-out forwards;
          animation: page-change .5s ease-in-out forwards;
}

iframe {
  outline: none;
  border: none;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
}

p {
  line-height: 130%;
  margin: 0;
  margin-block: 0;
  font-family: 'Poppins';
}

p.danger {
  color: #f85149;
}

strong.danger {
  color: #f85149;
}

label {
  font-family: 'Poppins';
  font-size: 16px;
}

@media screen and (max-width: 480px) {
  label {
    font-size: 14px;
  }
}

h1, h2, h3, h4 {
  font-family: "Poppins";
  margin: 0;
  font-weight: normal;
}

h1 {
  font-family: "Poppins";
  font-weight: 700;
  margin: 0;
  margin-block: 0;
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 34px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  h1 {
    font-size: 40px;
  }
}

@media screen and (min-width: 1366px) {
  h1 {
    font-size: 44px;
  }
}

h2 {
  font-family: "Poppins";
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  h2 {
    font-size: 23px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  h2 {
    font-size: 30px;
  }
}

@media screen and (min-width: 1366px) {
  h2 {
    font-size: 32px;
  }
}

h3 {
  font-family: "Poppins";
  font-weight: 500;
}

@media screen and (max-width: 480px) {
  h3 {
    font-size: 20px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  h3 {
    font-size: 22px;
  }
}

@media screen and (min-width: 1366px) {
  h3 {
    font-size: 24px;
  }
}

h4 {
  font-family: "Poppins";
  font-weight: 500;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  font-family: 'Poppins';
}

input {
  font-family: 'Poppins';
}

input[type=button], input[type=submit] {
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

select,
input,
textarea,
.search-select > div {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: normal;
  line-height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 15px;
  border-radius: 10px;
  outline: none;
  border: 1px solid #9A9A9A;
  -webkit-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
  margin: 0;
}

@media screen and (max-width: 480px) {
  select,
  input,
  textarea,
  .search-select > div {
    font-size: 14px;
  }
}

select:disabled,
input:disabled,
textarea:disabled,
.search-select > div:disabled {
  cursor: not-allowed;
  opacity: 1;
  background-color: whitesmoke;
  border-color: whitesmoke;
  color: #2b2b2b;
}

select::-webkit-input-placeholder,
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
.search-select > div::-webkit-input-placeholder {
  color: #9A9A9A;
}

select:-ms-input-placeholder,
input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
.search-select > div:-ms-input-placeholder {
  color: #9A9A9A;
}

select::-ms-input-placeholder,
input::-ms-input-placeholder,
textarea::-ms-input-placeholder,
.search-select > div::-ms-input-placeholder {
  color: #9A9A9A;
}

select::placeholder,
input::placeholder,
textarea::placeholder,
.search-select > div::placeholder {
  color: #9A9A9A;
}

select input:not([type=submit]):hover:not(:disabled), select input:not([type=submit]):focus:not(:disabled),
select input:not([type=button]):hover:not(:disabled),
select input:not([type=button]):focus:not(:disabled),
input input:not([type=submit]):hover:not(:disabled),
input input:not([type=submit]):focus:not(:disabled),
input input:not([type=button]):hover:not(:disabled),
input input:not([type=button]):focus:not(:disabled),
textarea input:not([type=submit]):hover:not(:disabled),
textarea input:not([type=submit]):focus:not(:disabled),
textarea input:not([type=button]):hover:not(:disabled),
textarea input:not([type=button]):focus:not(:disabled),
.search-select > div input:not([type=submit]):hover:not(:disabled),
.search-select > div input:not([type=submit]):focus:not(:disabled),
.search-select > div input:not([type=button]):hover:not(:disabled),
.search-select > div input:not([type=button]):focus:not(:disabled) {
  border-color: #0a6887;
}

select option,
input option,
textarea option,
.search-select > div option {
  font-family: 'Poppins';
}

.search-select > div {
  padding: 2.5px 5px;
}

button,
input[type=submit],
input[type=button] {
  cursor: pointer;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  background-color: white;
  color: #2b2b2b;
  border: 1px solid #2b2b2b;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

@media screen and (max-width: 480px) {
  button,
  input[type=submit],
  input[type=button] {
    font-size: 14px;
  }
}

button:disabled,
input[type=submit]:disabled,
input[type=button]:disabled {
  cursor: not-allowed;
  border: 1px solid #9A9A9A !important;
  background-color: #9A9A9A !important;
  color: white !important;
}

button:hover,
input[type=submit]:hover,
input[type=button]:hover {
  opacity: 90%;
}

button svg,
input[type=submit] svg,
input[type=button] svg {
  display: inline;
}

a {
  text-decoration: none;
  font-family: "Poppins";
}

a:disabled {
  cursor: not-allowed;
}

button {
  outline: none;
  border: none;
  background: none;
  margin: 0;
  border-radius: 20px;
  font-family: "Poppins";
}

button:disabled {
  cursor: not-allowed;
}

a, button {
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

svg {
  display: block;
}

table {
  font-family: 'Poppins';
  font-size: 16px;
}

@media screen and (max-width: 480px) {
  table {
    font-size: 14px;
  }
}

table th {
  text-align: start;
  font-weight: 500;
  line-height: 100%;
  font-size: 17px;
}

@media screen and (max-width: 480px) {
  table th {
    font-size: 15px;
  }
}

.inline-inputs {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  -webkit-column-gap: 30px;
          column-gap: 30px;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .inline-inputs {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
}

.labeled-input {
  position: relative;
}

.labeled-input select,
.labeled-input input,
.labeled-input textarea {
  padding: 12.5px 10px;
  line-height: 100%;
  font-weight: normal;
  border: none;
  border-radius: 0;
  border-bottom: 3px solid #9A9A9A;
  width: 100%;
}

.labeled-input select:focus,
.labeled-input input:focus,
.labeled-input textarea:focus {
  border-bottom-color: #0a6887;
}

.labeled-input select:focus ~ label,
.labeled-input input:focus ~ label,
.labeled-input textarea:focus ~ label {
  color: #0a6887;
}

@media screen and (max-width: 480px) {
  .labeled-input select,
  .labeled-input input,
  .labeled-input textarea {
    padding: 10px 7.5px;
  }
}

.labeled-input label {
  pointer-events: none;
  width: 100%;
  color: #9A9A9A;
  font-weight: normal;
  position: absolute;
  left: 10px;
  top: 0%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

@media screen and (max-width: 480px) {
  .labeled-input label {
    left: 7.5px;
  }
}

.input {
  position: relative;
  margin-bottom: 15px;
  text-align: start;
}

.input select:not([type=checkbox]),
.input input:not([type=checkbox]),
.input textarea:not([type=checkbox]),
.input .search-select:not([type=checkbox]) {
  width: 100%;
}

.input label,
.input p {
  display: block;
  width: 100%;
  padding-left: 3px;
  font-size: 90%;
  font-weight: 500;
  color: #2b2b2b;
}

.input p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 2.5px;
}

@media screen and (max-width: 480px) {
  .input p {
    font-size: 12px;
  }
}

.input.image-edit-input img {
  width: 100%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

@media screen and (min-width: 1366px) {
  .input.image-edit-input img {
    width: 50%;
  }
}

.floating-input {
  position: relative;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin-bottom: 15px;
}

.floating-input select,
.floating-input input {
  padding: 12.5px 10px;
  line-height: 100%;
  font-weight: normal;
  border: none;
  border-radius: 0;
  border: 1px solid #9A9A9A;
  border-radius: 10px;
  width: 100%;
}

.floating-input select::-webkit-input-placeholder,
.floating-input input::-webkit-input-placeholder {
  visibility: hidden;
}

.floating-input select:-ms-input-placeholder,
.floating-input input:-ms-input-placeholder {
  visibility: hidden;
}

.floating-input select::-ms-input-placeholder,
.floating-input input::-ms-input-placeholder {
  visibility: hidden;
}

.floating-input select::placeholder,
.floating-input input::placeholder {
  visibility: hidden;
}

.floating-input select:focus,
.floating-input input:focus {
  border-color: #0a6887;
}

.floating-input select:focus::-webkit-input-placeholder,
.floating-input input:focus::-webkit-input-placeholder {
  visibility: visible;
}

.floating-input select:focus:-ms-input-placeholder,
.floating-input input:focus:-ms-input-placeholder {
  visibility: visible;
}

.floating-input select:focus::-ms-input-placeholder,
.floating-input input:focus::-ms-input-placeholder {
  visibility: visible;
}

.floating-input select:focus::placeholder,
.floating-input input:focus::placeholder {
  visibility: visible;
}

.floating-input select:focus ~ label,
.floating-input input:focus ~ label {
  color: #0a6887;
  font-size: 90%;
  top: 0%;
}

@media screen and (max-width: 480px) {
  .floating-input select,
  .floating-input input {
    padding: 10px 7.5px;
  }
}

.floating-input select:not([value=""]):valid,
.floating-input input:not(:placeholder-shown) {
  border-color: #0a6887;
}

.floating-input select:not([value=""]):valid ~ label,
.floating-input input:not(:placeholder-shown) ~ label {
  color: #0a6887;
  font-size: 90%;
  top: 0%;
}

.floating-input label {
  pointer-events: none;
  background-color: white;
  color: #9A9A9A;
  font-weight: normal;
  line-height: 100%;
  position: absolute;
  left: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0 7.5px;
}

@media screen and (max-width: 480px) {
  .floating-input label {
    left: 10px;
  }
}

.radio-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.radio-input input {
  margin-right: 5px;
}

.inline-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .inline-btns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.inline-btns button,
.inline-btns input[type=submit],
.inline-btns input[type=button] {
  border-radius: 15px;
  font-weight: 500;
  line-height: 100%;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  font-size: 18px;
}

@media screen and (max-width: 480px) {
  .inline-btns button,
  .inline-btns input[type=submit],
  .inline-btns input[type=button] {
    width: 100%;
    font-size: 16px;
  }
}

@media screen and (min-width: 481px) {
  .inline-btns button,
  .inline-btns input[type=submit],
  .inline-btns input[type=button] {
    width: 50%;
  }
  .inline-btns button:not(:last-of-type),
  .inline-btns input[type=submit]:not(:last-of-type),
  .inline-btns input[type=button]:not(:last-of-type) {
    margin-right: 10px;
  }
}

.stacked-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .stacked-btns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.stacked-btns button,
.stacked-btns input[type=submit],
.stacked-btns input[type=button] {
  border-radius: 15px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

@media screen and (max-width: 480px) {
  .stacked-btns button,
  .stacked-btns input[type=submit],
  .stacked-btns input[type=button] {
    font-size: 16px;
  }
}

@media screen and (min-width: 481px) {
  .stacked-btns button:not(:last-of-type),
  .stacked-btns input[type=submit]:not(:last-of-type),
  .stacked-btns input[type=button]:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.live-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
  position: relative;
  background-color: white;
  border-radius: 10px;
}

@media screen and (max-width: 480px) {
  .live-search {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
  }
}

.live-search.open {
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
}

.live-search.open .live-search-results {
  position: absolute;
  top: 100%;
  background-color: white;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border-radius: 0 0 10px 10px;
  border: 1px solid #0a6887;
  border-top: none;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 10px 20px !important;
}

.live-search.open .live-search-input {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.live-search.open .live-search-input input {
  border-radius: 10px 10px 0 0;
  border: 1px solid #0a6887;
  border-bottom: none;
  -webkit-transition: none !important;
  transition: none !important;
}

.live-search-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  position: relative;
}

.live-search-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: start;
  font-weight: 500;
  border-radius: 10px;
  color: rgba(43, 43, 43, 0.8);
  background-color: white;
  z-index: 3;
  width: 100%;
}

.live-search-input input {
  width: 100%;
  padding: 12.5px 10px;
}

@media screen and (max-width: 480px) {
  .live-search-input input {
    padding: 10px 7.5px;
  }
}

.live-search-input button {
  position: absolute;
  top: 0%;
  height: 100%;
  padding: 10px 15px;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.live-search-input button:nth-of-type(1) {
  right: 0;
}

.live-search-input button:nth-of-type(1):hover svg {
  fill: #f85149;
}

.live-search-input button:nth-of-type(1) svg {
  fill: #2b2b2b;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  height: 15px;
}

.live-search-results {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0 20px;
  height: 0;
  width: 100%;
  overflow: hidden;
  z-index: 2;
  max-height: 25vh;
  overflow-y: auto;
}

@media screen and (max-width: 480px) {
  .live-search-results {
    width: 100%;
  }
}

.live-search-results-result {
  position: relative;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 5px 0;
}

.live-search-results-result p {
  max-width: 75%;
}

.live-search-results-result button {
  position: absolute;
  right: 0%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 0;
  color: #0a6887;
  font-weight: 500;
}

.live-search-results-result button svg {
  fill: #0a6887;
  height: 20px;
  width: auto;
}

.live-search-results-minimize {
  position: absolute;
  right: 20px;
  font-weight: 500;
  opacity: 80%;
  font-size: 90%;
  padding: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.live-search-results-info {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  margin: 25px 0 5px;
}

.live-search-results-info-heading {
  font-size: 90%;
  color: #9A9A9A;
}

.live-search-results-info-count {
  font-size: 75%;
  color: #9A9A9A;
  width: 100%;
  text-align: end;
}

.dates-picker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
  background-color: white;
  border-radius: 20px;
}

@media screen and (max-width: 480px) {
  .dates-picker {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
  }
}

.dates-picker.open {
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
}

.dates-picker.open .dates-picker-inputs {
  position: absolute;
  top: 100%;
  background-color: white;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border-radius: 0 0 20px 20px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 10px 20px 20px !important;
}

.dates-picker.open .dates-picker-toggle {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 20px 20px 0 0;
  -webkit-transition: none !important;
  transition: none !important;
  z-index: 3;
}

.dates-picker.open .dates-picker-toggle svg:nth-of-type(2) {
  rotate: -90deg;
}

.dates-picker-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  position: relative;
}

.dates-picker-wrapper.reports {
  margin-bottom: 30px;
}

@media screen and (max-width: 480px) {
  .dates-picker-wrapper.reports {
    margin-bottom: 20px;
  }
}

.dates-picker-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: start;
  font-weight: 500;
  border-radius: 15px;
  color: rgba(43, 43, 43, 0.8);
  background-color: white;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.418);
          box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.418);
  z-index: 3;
}

.dates-picker-toggle svg {
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  fill: rgba(43, 43, 43, 0.8);
}

.dates-picker-toggle svg:nth-of-type(1) {
  margin-right: 10px;
}

.dates-picker-toggle svg:nth-of-type(2) {
  width: 7.5px;
  margin-left: 20px;
  rotate: 90deg;
}

@media screen and (max-width: 480px) {
  .dates-picker-toggle {
    width: 100%;
  }
}

.dates-picker-inputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0 20px;
  height: 0;
  width: 100%;
  overflow: hidden;
  z-index: 2;
}

@media screen and (max-width: 480px) {
  .dates-picker-inputs {
    width: 100%;
  }
}

.dates-picker-inputs fieldset {
  margin-bottom: 15px;
}

.dates-picker-inputs-pickers p {
  margin: 0 5px 2.5px;
  text-align: start;
}

.dates-picker-inputs-pickers input[type="date"] {
  width: 100%;
}

.dates-picker-inputs-pickers input[type="date"]:not(:last-of-type) {
  margin-bottom: 10px;
}

.dates-picker-inputs-pickers input[type="button"] {
  width: 100%;
  margin-top: 10px;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.418);
          box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.418);
  background-color: #0a6887;
  color: white;
}

.modal {
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border-radius: 15px;
  background-color: white;
  padding: 30px;
  width: 75%;
}

.modal-wrapper {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.modal-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}

.modal-top h3 {
  margin-bottom: 0 !important;
}

.modal-top button {
  position: relative !important;
  top: 0% !important;
  right: 0% !important;
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
}

.modal-top button:hover {
  background-color: inherit !important;
}

.modal-top button svg {
  fill: #2b2b2b !important;
  -webkit-transform: rotate(180deg) !important;
          transform: rotate(180deg) !important;
  height: 17.5px !important;
}

.input-search {
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border-radius: 15px;
  background-color: white;
  padding: 30px;
  width: 75%;
}

.input-search-wrapper {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

.input-search p {
  margin-bottom: 10px;
}

.input-search-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}

.input-search-top h3 {
  margin-bottom: 0 !important;
}

.input-search-top button {
  position: relative;
  top: 0%;
  right: 0%;
  padding: 0;
}

.input-search-top button svg {
  fill: #2b2b2b;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  height: 17.5px;
}

.input-search-selectedvalue {
  font-size: 16px !important;
  font-weight: normal !important;
  line-height: 100% !important;
  margin: 5px 0 !important;
}

.input-search form {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  padding: 0 !important;
}

@media screen and (max-width: 480px) {
  .password-requirements {
    margin-top: 30px;
  }
}

@media screen and (min-width: 481px) {
  .password-requirements {
    margin-bottom: 25px;
  }
}

.password-requirements li {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 16px auto;
      grid-template-columns: 16px auto;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style: none;
  line-height: 115%;
  margin-bottom: 5px;
  color: #9A9A9A;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.password-requirements li svg {
  fill: #9A9A9A;
  height: 16px;
  width: 16px;
}

.password-requirements li.failed {
  color: #f85149;
}

.password-requirements li.failed svg {
  fill: #f85149;
}

.password-requirements li.passed {
  color: #0a6887;
}

.password-requirements li.passed svg {
  fill: #0a6887;
}

@-webkit-keyframes page-change {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes page-change {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.header {
  /*  display: grid;
    grid-template-columns: 1fr 1fr  1fr ;
    grid-template-rows: minmax(15px, 1fr) minmax(15px, 1fr) ;
    grid-template-areas: 
    "main main main"
    "search_ search_ search_"
    ;*/
  background-color: white;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: -ms-grid;
  display: grid;
  z-index: 100;
}

@media screen and (max-width: 480px) {
  .header {
    padding-right: 25px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .header {
    padding-right: 35px;
  }
}

@media screen and (min-width: 1366px) {
  .header {
    padding-right: 50px;
  }
}

.header-logo {
  -o-object-fit: contain;
     object-fit: contain;
  width: 75px;
}

.header .HeaderWrapper {
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: minmax(150px, 1fr) minmax(150px, 1fr);
      grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr);
  -ms-grid-rows: minmax(1fr, 150px) minmax(1fr, 150px);
      grid-template-rows: minmax(1fr, 150px) minmax(1fr, 150px);
      grid-template-areas: "logo   navheader" "dropdown  dropdown";
}

.header .HeaderWrapper .navheader {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: navheader;
}

.header .HeaderWrapper .logo {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: logo;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}

.header .HeaderWrapper .Header_Cart {
  grid-area: Header_Cart;
  background-color: #00c94d;
}

.header .HeaderWrapper .search_ {
  background-color: beige;
  grid-area: search_;
}

.header .HeaderWrapper .dropdown {
  margin: 0 auto;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: dropdown;
}

.RightNav {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  place-items: center;
}

.RightNav .CartCount {
  background-color: red;
  color: white;
  font-size: 0.8rem;
  padding: 0.1rem 0.5rem;
  border-radius: 50%;
  top: -0.5rem;
  right: -0.5rem;
}

.header {
  /* your existing header styles */
}

/* .sticky {
   position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
   /*  additional styles for sticky header */
.dropdown {
  position: relative;
  margin: 0 auto;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: minmax(1fr, 150px) minmax(1fr, 150px) minmax(1fr, 150px);
      grid-template-columns: minmax(1fr, 150px) minmax(1fr, 150px) minmax(1fr, 150px);
}

.dropdown .dropdown-toggle {
  width: 30%;
  max-width: 150px;
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
}

.dropdown .dropdown-toggle:hover {
  background-color: #f1f1f1;
}

.dropdown .sectionwrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
}

.dropdown .sectionwrapper .section {
  display: -ms-grid;
  display: grid;
}

.dropdown .sectionwrapper .section .dropdown-menu {
  width: 100%;
  z-index: 1;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  min-width: 160px;
  padding: 8px 0;
}

.dropdown .sectionwrapper .section .dropdown-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdown .sectionwrapper .section .dropdown-menu ul li {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown .sectionwrapper .section .dropdown-menu ul li:hover {
  background-color: #f1f1f1;
}

@media screen and (min-width: 600px) {
  .dropdown .sectionwrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
  }
  .dropdown .dropdown-toggle {
    width: 100%;
  }
  .dropdown .dropdown-menu {
    width: 100%;
  }
}

nav {
  position: relative;
}

nav .nav-user {
  position: relative;
}

nav .nav-user button {
  cursor: pointer;
  position: relative;
  z-index: 2;
  padding: 12.5px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-transition-delay: .1s;
          transition-delay: .1s;
}

nav .nav-user button svg {
  height: 24px;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  fill: #2b2b2b;
}

nav .nav-user button:hover svg {
  fill: #0a6887;
}

nav .nav-user button.open {
  background-color: white;
  border-radius: 15px 15px 0 0;
  -webkit-box-shadow: 0px -15px 25px 0px rgba(0, 0, 0, 0.158);
          box-shadow: 0px -15px 25px 0px rgba(0, 0, 0, 0.158);
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

nav .nav-user button.open svg {
  fill: #0a6887;
}

nav .nav-user-dropdown {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  overflow: hidden;
  border-radius: 20px 0px 20px 20px;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.418);
          box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.418);
  width: 0;
  height: 0;
  padding: 0;
  -webkit-transition: width 0.3s ease-in-out, padding 0.3s ease-in-out, height 0.2s ease-in-out;
  transition: width 0.3s ease-in-out, padding 0.3s ease-in-out, height 0.2s ease-in-out;
  background-color: white;
}

nav .nav-user-dropdown.open {
  display: block;
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 20px 25px;
  -webkit-transition: height 0.3s ease-in-out, padding 0.3s ease-in-out, width 0.2s ease-in-out;
  transition: height 0.3s ease-in-out, padding 0.3s ease-in-out, width 0.2s ease-in-out;
}

@media screen and (max-width: 480px) {
  nav .nav-user-dropdown.open {
    width: 50vw;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  nav .nav-user-dropdown.open {
    width: 25vw;
  }
}

@media screen and (min-width: 1366px) {
  nav .nav-user-dropdown.open {
    width: 10vw;
  }
}

.nav-user button svg {
  fill: #2b2b2b;
}

nav .nav-user-dropdown a {
  display: block;
  line-height: 100%;
  color: #0a6887;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

nav .nav-user-dropdown a:not(:last-of-type) {
  margin-bottom: 10px;
}

nav .nav-user-dropdown a:hover {
  opacity: 90%;
}

.addtocartBtn {
  background-color: transparent;
  color: #14185B;
  border-radius: 5px;
  width: 90%;
  padding: 5px;
}

.addtocartBtn:hover {
  background-color: #14185B;
  color: white;
}

.image-slider.empty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  width: 100%;
}

.image-slider.empty p {
  font-size: 2rem;
  color: gray;
}

.image-slider .image-wrapper {
  position: relative;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: image-wrapper;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.image-slider .image-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.image-slider .image-wrapper.zoomed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.image-slider .nav {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: nav;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.image-slider .nav .nav-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 2rem;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.image-slider .nav .nav-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.image-slider .nav .prev {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.image-slider .image-selector {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: image-selector;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}

.image-slider .image-selector .image-selector-button {
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.image-slider .image-selector .image-selector-button:hover, .image-slider .image-selector .image-selector-button.active {
  background-color: rgba(0, 0, 0, 0.5);
}

.image-slider .image-selector .image-selector-button.active {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.image-slider .report-button {
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: report-button;
  background-color: white;
  border: 2px solid black;
  color: black;
  font-size: 2rem;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.image-slider .report-button:hover {
  background-color: black;
  color: white;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.image-slider-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.image-slider-modal .image-slider {
  display: -ms-grid;
  display: grid;
      grid-template-areas: "image-wrapper" "nav" "image-selector" "report-button";
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: 1fr auto auto auto;
      grid-template-rows: 1fr auto auto auto;
  width: 80%;
  height: 80%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .image-slider-modal .image-slider {
        grid-template-areas: "image-wrapper nav" "image-selector image-selector" "report-button report-button";
    -ms-grid-columns: 1fr 50px;
        grid-template-columns: 1fr 50px;
    -ms-grid-rows: 1fr auto auto;
        grid-template-rows: 1fr auto auto;
    width: 90%;
    height: 90%;
  }
}

.image-slider-modal .image-slider.empty {
      grid-template-areas: "empty-message empty-message" "nav nav" "image-selector image-selector" "report-button report-button";
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr auto auto auto;
      grid-template-rows: 1fr auto auto auto;
}

.image-slider-modal .image-slider.empty .empty-message {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: empty-message;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  width: 100%;
}

.image-slider-modal .image-slider.empty .empty-message p {
  font-size: 2rem;
  color: gray;
}

/*.imagerow{
    background-color: aqua;

display: grid;


.image-wrapper{
    background-color: aquamarine;
    display: grid;

        .image{
            display: grid;
            background-color: bisque;

            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            img{
            max-width:150px;

            }
        }

    }
}*/
.imagerow .image-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 15px;
}

.imagerow .image-wrapper::-webkit-scrollbar {
  display: none;
}

.imagerow .image-wrapper .image {
  margin: 0 auto;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.imagerow .image-wrapper .image img {
  border: 2px solid #359c8d;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  max-width: 50px;
}

.imagerowmodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 1000;
}

.imagerowmodal-content {
  padding: 10px;
  /* width: 80%;
      height: 80%;*/
  max-width: 500px;
  max-height: 500px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  background-color: white;
  border-radius: 15px;
  /*   width: 80%;
        height: 80%;*/
}

.imagerowmodal-content .modal-image {
  margin: 0 auto;
}

.imagerowmodal-content .modal-image img {
  max-width: 450px;
  max-height: 450px;
}

.imagerowmodal .Title {
  font-size: 30px;
  color: #0a6887;
}

@media screen and (max-width: 768px) {
  .Title {
    font-size: 15px;
  }
  .imagerowmodal-content {
    margin: 0 auto;
    padding: 10px;
    width: 80%;
    height: 80%;
  }
  .imagerowmodal-content .modal-image img {
    max-width: 250px;
    max-height: 250px;
  }
  .imagerow .image-wrapper .image img {
    max-width: 100px;
  }
}

.remove-button:hover {
  background-color: #cc0000;
}

.remove-button {
  background-color: #ff1a1a;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.Create_Payment_Voucher {
  margin: 0 auto;
  width: 80%;
}

.Create_Payment_Voucher .Items {
  margin: 0 auto;
  width: 100%;
  border: 3px solid #0a6887;
  border-radius: 15px;
  overflow: hidden;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
}

.Create_Payment_Voucher .Items table {
  width: 100%;
}

.Create_Payment_Voucher .Items table .row_item {
  border-bottom: 1px solid #404040;
}

.Add_itemVoucher {
  width: 100%;
  text-align: center;
}

.PaymentMode {
  display: -ms-grid;
  display: grid;
}

.voucherinfo {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 1fr 1fr;
      grid-template-areas: "payee PaymentMode" "payee selectcategory";
}

.voucherinfo .selectcategory {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  grid-area: selectcategory;
}

.voucherinfo .PaymentMode {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: PaymentMode;
}

.voucherinfo .payee {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
  grid-area: payee;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.loader-wrapper {
  -webkit-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.975);
  z-index: 1000;
}

.loader-wrapper.hidden {
  display: none;
}

.loader-wrapper.hidden svg {
  -webkit-animation: none;
          animation: none;
}

.loader-wrapper.disabled {
  opacity: 0;
}

.loader-wrapper.enabled {
  display: block;
}

.loader-wrapper svg {
  -webkit-animation: spin .75s normal linear infinite;
          animation: spin .75s normal linear infinite;
}

.loader-wrapper p {
  margin-top: 20px;
  font-weight: 500;
}

.loader svg {
  fill: #0a6887;
  width: 75px;
  height: 75px;
}

@media screen and (max-width: 480px) {
  .loader svg {
    width: 50px;
    height: 50px;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
            transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
            transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
            transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
            transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
            transform: rotate3d(0, 0, 1, 360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
            transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
            transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
            transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
            transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
            transform: rotate3d(0, 0, 1, 360deg);
  }
}

.alert {
  position: fixed;
  top: -20%;
  left: 50%;
  -webkit-transform: translate(-50%, 25%);
          transform: translate(-50%, 25%);
  border-radius: 30px;
  border: 1px solid #e5e5e5;
  padding: 25px 10px;
  background-color: white;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 14px;
}

@media screen and (min-width: 1366px) {
  .alert {
    width: 30%;
  }
}

.alert-wrapper {
  display: none;
}

.alert.active {
  top: 0%;
}

.alert p {
  text-align: center;
}

.BrandCard_wrapper {
  display: -ms-grid;
  display: grid;
  margin: 0 auto;
  grid-gap: 15px;
}

.BrandCard_wrapper .BrandCard {
  display: -ms-grid;
  display: grid;
  width: 100%;
  height: 100%;
  max-width: 250px;
  max-height: 250px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.BrandCard_wrapper .BrandCard .ItemName {
  font-size: 16px;
  font-weight: 600;
  color: #c01f1f;
  text-align: center;
  padding: 1rem;
}

.BrandCard_wrapper .BrandCard .ProductCount {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}

.BrandCard_wrapper .BrandCard .ArchCount {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .BrandCard_wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .BrandCard_wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
}

@media screen and (min-width: 1280px) {
  .BrandCard_wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
}

@media screen and (min-width: 1440px) {
  .BrandCard_wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
}

@media screen and (min-width: 1600px) {
  .BrandCard_wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
}

.productArch {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: 5px 5px;
  font-size: 11px;
  padding: 15px;
}

.fileuploadmodal {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-area: fileuploadmodal;
}

.iamgeupload {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
  grid-area: iamgeupload;
}

.iamgeupload button {
  font-size: 9px;
}

.title {
  background-color: #0a6887;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-weight: 600;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 0;
  padding: 10px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row: 1fr 1fr;
      grid-template-areas: "titlename ArcCode OEM_Code iamgeupload" "fileuploadmodal fileuploadmodal fileuploadmodal fileuploadmodal";
}

.title .ArcCode {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: ArcCode;
}

.title .OEM_Code {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: OEM_Code;
}

.title .iamgeupload button {
  color: white;
  font-size: 15px;
  padding: 0;
  margin: 0;
}

.products {
  display: -ms-grid;
  display: grid;
}

.product {
  font-weight: 600;
  width: 90%;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  margin: 10px auto;
  -ms-grid-rows: 1fr 1fr minmax(1fr, 100px) auto 1fr;
      grid-template-rows: 1fr 1fr minmax(1fr, 100px) auto 1fr;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  border: 2px solid #25D442;
  background-color: #F3FFF5;
  font-size: 10px;
  text-align: center;
      grid-template-areas: "productname productname"  "suppliercode Acode" "Model brand_Name" "Unit_Price quantity" "Supplier Supplier" "addtocart addtocart";
  /*
grid-template-areas: 
"suppliercode "
"Acode "
"productname " 
" brand_Name"
"Unit_Price "
"quantity "
"Supplier "
;*/
}

.product.onunits {
  border: 2px solid #292929;
  border: none;
  background-color: #e2e2e2;
}

.product.unmoved {
  border: 2px solid #FF0000;
  background-color: #FFF8F8;
}

.product:hover {
  -webkit-transition: .5s;
  transition: .5s;
  background-color: #25d442a2;
}

.product:hover.onunits {
  background-color: #2929296c;
}

.product:hover.unmoved {
  background-color: #ff00005d;
}

.product a {
  -webkit-transition: .8s;
  transition: .8s;
  color: #359c8d;
  color: black;
}

.product .suppliercode {
  text-align: left;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: suppliercode;
}

.product .productname {
  text-align: left;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: productname;
}

.product .Acode {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  grid-area: Acode;
  text-align: right;
}

.product .Model {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: Model;
}

.product .brand_Name {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  text-align: right;
  -ms-grid-row: 3;
  -ms-grid-column: 2;
  grid-area: brand_Name;
}

.product .quantity {
  -ms-grid-row: 4;
  -ms-grid-column: 2;
  grid-area: quantity;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.product .Unit_Price {
  text-align: left;
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  grid-area: Unit_Price;
}

.product .Supplier {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: Supplier;
}

.product .addtocart {
  -ms-grid-row: 8;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: addtocart;
}

.product .Flagitem {
  grid-area: Flagitem;
}

.product .BillingGroup {
  grid-area: BillingGroup;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 15px;
  }
  .product {
    font-size: 15px;
    display: -ms-grid;
    display: grid;
    width: 100%;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    -ms-grid-columns: (minmax(150px, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    /* set a fixed width of 200px for each column */
    -ms-grid-rows: minmax(1fr, 15px) minmax(1fr, 15px) minmax(1fr, 15px);
        grid-template-rows: minmax(1fr, 15px) minmax(1fr, 15px) minmax(1fr, 15px);
        grid-template-areas: "suppliercode productname productname Flagitem Acode  Model brand_Name Unit_Price quantity Supplier BillingGroup addtocart" "suppliercode productname productname Flagitem Acode  Model brand_Name Unit_Price quantity Supplier BillingGroup addtocart" "suppliercode productname productname Flagitem Acode  Model brand_Name Unit_Price quantity Supplier BillingGroup addtocart";
  }
  .product .Unit_Price {
    text-align: right;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 8;
    grid-area: Unit_Price;
  }
  .product .Acode {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 5;
    grid-area: Acode;
    text-align: center;
  }
}

.list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.item {
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: #fff;
  cursor: -webkit-grab;
  cursor: grab;
}

.item:hover {
  background-color: #f4f4f4;
}

.item:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
  background-color: #eee;
}

.brand_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.productbrandselectlist {
  width: 100%;
  margin: 0 auto;
  height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}

.productbrandselectlist .productbrandselect {
  margin: 15px 0;
  border: 1px solid #0a6887;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  width: 80%;
  font-size: 12px;
  font-weight: 600;
  color: #0a6887;
  cursor: pointer;
  text-align: left;
  list-style-type: none;
}

.productbrandselectlist .productbrandselect:hover {
  background-color: #0a6887;
  color: white;
}

.Modal_parent {
  background-color: #ff0d0d;
  width: 100%;
}

.modal-content input {
  font-size: 16px;
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

/*
.modal-content button {
    font-size: 18px;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }*/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent black background */
  z-index: 999;
}

.modal-content {
  background-color: #ffffff;
  margin: 0 auto;
  height: 95%;
  width: 100%;
  display: -ms-grid;
  display: grid;
  /* ... */
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border-radius: 10px;
  border: 2px solid #259ED4;
  grid-gap: 15px;
  padding: 10px;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: minmax(15px, 0.5fr) auto auto auto auto auto auto;
      grid-template-rows: minmax(15px, 0.5fr) auto auto auto auto auto auto;
      grid-template-areas: "Productincartmodal Productincartmodal" "Quantity Quantity" "discount discount" "Total Total" "note note" "paymentmode paymentmode" "creditterms creditterms" "addtocart addtocart";
}

.modal-content .Productincartmodal {
  display: -ms-grid;
  display: grid;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: Productincartmodal;
}

.modal-content .Quantity {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: Quantity;
  width: 90%;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  place-content: center;
}

.modal-content .discount {
  display: -ms-grid;
  display: grid;
  width: 90%;
  margin: 0 auto;
  place-content: center;
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: discount;
}

.modal-content .paymentmode {
  display: -ms-grid;
  display: grid;
  -ms-grid-row: 6;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: paymentmode;
}

.modal-content .creditterms {
  display: -ms-grid;
  display: grid;
  -ms-grid-row: 7;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: creditterms;
}

.modal-content .note {
  display: -ms-grid;
  display: grid;
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: note;
  width: 100%;
}

.modal-content .addtocart {
  display: -ms-grid;
  display: grid;
  -ms-grid-row: 8;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: addtocart;
  /*button{
    //width:90%;

                background-color: #359c8d;
                color: rgb(255, 255, 255);
            }*/
}

.modal-content .closecartmodalbtn {
  display: -ms-grid;
  display: grid;
  -ms-grid-column-align: end;
      justify-self: end;
  place-content: center;
  grid-area: closecartmodalbtn;
}

.modal-content .closecartmodalbtn button {
  background-color: red;
  color: white;
}

.modal-content .Total {
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: Total;
}

@media (min-width: 767px) {
  .modal-content {
    width: 90%;
    /* ... */
    display: -ms-grid;
    display: grid;
    height: auto;
    -ms-grid-columns: minmax(15px, 1fr) minmax(15px, 1fr) minmax(15px, 1fr) minmax(15px, 1fr);
        grid-template-columns: minmax(15px, 1fr) minmax(15px, 1fr) minmax(15px, 1fr) minmax(15px, 1fr);
    -ms-grid-rows: 50px 1fr 1fr 1fr;
        grid-template-rows: 50px 1fr 1fr 1fr;
    grid-gap: 15px;
    padding: 15px;
        grid-template-areas: "Productincartmodal Productincartmodal    Productincartmodal Productincartmodal" "Quantity discount note note" "paymentmode   creditterms .  Total "  "addtocart addtocart addtocart addtocart";
  }
  .modal-content .Productincartmodal {
    width: 100%;
    height: 10%;
  }
  .modal-content .closecartmodalbtn {
    -ms-grid-column-align: start;
        justify-self: start;
  }
  .modal-content input {
    font-size: 20px;
    padding: 20px;
    margin-bottom: 20px;
  }
  .modal-content button {
    font-size: 24px;
    padding: 20px;
  }
}

.paymentmode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
}

.paymentmode button {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border: none;
  padding: 10px;
  font-size: 1em;
  color: #ffffff;
  background-color: #bbbbbb;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.paymentmode button.selected {
  background-color: #007bff;
}

.cartContainer {
  background-color: aqua;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 1rem;
}

@media (min-width: 768px) {
  .cartContainer {
    margin: 2rem;
  }
}

@media (min-width: 1024px) {
  .cartContainer {
    margin: 3rem;
  }
}

.tableHead {
  display: none;
}

@media (min-width: 768px) {
  .tableHead {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    padding: 1rem;
    font-weight: bold;
    background-color: #f2f2f2;
    margin-bottom: 1rem;
  }
}

.tableBody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

@media (min-width: 768px) {
  .tableBody {
    overflow-x: auto;
  }
}

.tableBody > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
}

@media (min-width: 768px) {
  .tableBody > div {
    margin-bottom: 1rem;
  }
}

.CartPage {
  font-size: 13px;
  display: -ms-grid;
  display: grid;
  width: 95%;
  margin: 0 auto;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: minmax(1fr, 250px) 1fr 1fr minmax(1fr, 250px);
      grid-template-rows: minmax(1fr, 250px) 1fr 1fr minmax(1fr, 250px);
      grid-template-areas: "customer . Cartemptymessage totalItemsincart" "CartItems CartItems CartItems CartItems" "CartItems CartItems CartItems CartItems" "cartbottom cartbottom cartbottom cartbottom";
}

.CartPage .totalItemsincart {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
  grid-area: totalItemsincart;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  place-items: center;
  display: -ms-grid;
  display: grid;
}

.CartPage .CartItems {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-area: CartItems;
  display: -ms-grid;
  display: grid;
  border-radius: 10px;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: ()[auto];
      grid-template-rows: repeat(auto);
  border: 1px solid #0a6887;
  margin: 15px auto;
  width: 90%;
}

.CartPage .Cartemptymessage {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: Cartemptymessage;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  place-items: center;
  display: -ms-grid;
  display: grid;
}

.CartRow {
  -webkit-box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.233);
          box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.233);
  display: -ms-grid;
  display: grid;
  margin: 10px 0;
  border-radius: 5px;
  -ms-grid-columns: 1fr .1fr;
      grid-template-columns: 1fr .1fr;
  -ms-grid-row: 1;
  grid-row: 1fr;
      grid-template-areas: "cartprodcut  Remove";
}

.CartRow .cartprodcut {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: cartprodcut;
}

.CartRow .Discount {
  -ms-grid-row: 3;
  -ms-grid-column: 2;
  grid-area: Discount;
}

.CartRow .Quantity {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: Quantity;
}

.CartRow .Subtotal {
  grid-area: Subtotal;
}

.CartRow .Remove {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: Remove;
  font-weight: 600;
  color: red;
  margin: 0 auto;
}

.cartbottom {
  width: 90%;
  margin: 0 auto;
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-area: cartbottom;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: "clearcart placeorder carttotal";
}

.cartbottom .carttotal {
  display: -ms-grid;
  display: grid;
  font-weight: 600;
  font-size: 20px;
  text-align: right;
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: carttotal;
  margin: auto;
}

.cartbottom .placeorder {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: placeorder;
}

.cartbottom .clearcart {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: clearcart;
}

.customer {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: customer;
  max-width: 400px;
  border-radius: 15px;
  margin: 15px auto;
  font-weight: 600;
  font-size: 18px;
  border: 2px solid #0a6887;
  width: 80%;
  padding: 10px;
  background-color: #EEEFFF;
  text-align: left;
  display: -ms-grid;
  display: grid;
  place-content: center;
}

.customer .CustomerSelectlist {
  text-align: left;
  width: 100%;
  margin: 0 auto;
  height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}

.customer .CustomerSelectlist .CustomerSelect {
  margin: 15px 0;
  border: 1px solid #0a6887;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  color: #0a6887;
  cursor: pointer;
  list-style-type: none;
}

.customer .CustomerSelectlist .CustomerSelect:hover {
  background-color: #0a6887;
  color: white;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #043747;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #0a6887;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background-color: #0a6887;
  border-radius: 50px;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(0.5, rgba(255, 255, 255, 0.2)), color-stop(0.5, transparent), to(transparent));
}

.button_one {
  background-color: #EEEFFF;
  color: black;
  border: 2px solid #14185B;
  border-radius: 5px;
  width: 90%;
  max-width: 250px;
  margin: 10px auto;
  padding: 15px;
}

.button_one:hover {
  background-color: #14185B;
  color: white;
}

.button_one.danger {
  background-color: #f85149;
  color: white;
  border: 2px solid #f85149;
}

.button_one.danger:hover {
  background-color: #f85149;
  color: white;
}

.button_one.Place_invoice {
  border: 2px solid #359c8d;
  background-color: #EEEFFF;
  color: #359c8d;
}

.button_one.Place_invoice:hover {
  background-color: #359c8d;
  color: white;
}

.fileuploadmodal {
  display: -ms-grid;
  display: grid;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 9999;
  overflow: auto;
}

.fileuploadmodal .fileuploadmodalmodal-content {
  display: -ms-grid;
  display: grid;
  margin: auto auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  height: 90%;
  max-height: 100VH;
  z-index: 9999;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: minmax(1fr, 150px) minmax(3fr, 300px) minmax(1fr, 150px);
      grid-template-rows: minmax(1fr, 150px) minmax(3fr, 300px) minmax(1fr, 150px);
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-header {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  z-index: 9999;
  color: #0a6887;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-header h2 {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-header .close-button {
  border: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body {
  display: -ms-grid;
  display: grid;
  width: 95%;
  z-index: 9999;
  margin: 0 auto;
  height: 100%;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: minmax(5fr, 300px) minmax(1fr, 150px);
      grid-template-rows: minmax(5fr, 300px) minmax(1fr, 150px);
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .dropzone {
  max-height: 100vh;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  color: #0a6887;
  margin: 0 auto;
  border-radius: 5px;
  width: 90%;
  height: 90%;
  border: 2px dashed black;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 9999;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .dropzone p {
  font-size: 16px;
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .selected-files {
  height: 20%;
  max-height: 150px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .selected-files .file {
  color: #0a6887;
  display: -ms-grid;
  display: grid;
  padding: 10px;
  max-width: 150px;
  max-height: 150px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .selected-files .file .image-preview {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .selected-files .file .preview-image {
  display: -ms-grid;
  display: grid;
  width: 100%;
  height: 100%;
  max-height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .selected-files .file p {
  margin-right: 10px;
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-body .selected-files .file button {
  border: none;
  background-color: transparent;
  color: red;
  cursor: pointer;
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-footer {
  display: -ms-grid;
  display: grid;
  margin-top: 20px;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-footer button {
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-footer button:first-child {
  background-color: #4caf50;
  color: white;
}

.fileuploadmodal .fileuploadmodalmodal-content .fileuploadmodalmodal-footer button:last-child {
  background-color: #f44336;
  color: white;
}

.area {
  width: 100%;
  height: 100vh;
  position: absolute;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #359c8d;
  -webkit-animation: animate 25s linear infinite;
          animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 12s;
          animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
  -webkit-animation-duration: 45s;
          animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 35s;
          animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 11s;
          animation-duration: 11s;
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

@keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.wrapper_Dashboard_Director {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 5fr;
      grid-template-columns: 1fr 5fr;
      grid-template-areas: "DirectorQuickAccess DirectorDashboard";
  background-color: white;
}

.DirectorQuickAccess {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: DirectorQuickAccess;
  background-color: white;
  width: 100%;
  height: 100%;
}

.DirectorQuickAccess .SalesSummary {
  position: relative;
  text-align: center;
  max-height: 150px;
  width: 80%;
  height: 100%;
  max-width: 300px;
  margin: 0 auto;
  border-radius: 15px;
  background-color: #f8f8f8;
  border: 2px #359c8d solid;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas: "h4 h4 h4" "h4 h4 h4" "totalOrders TotalSales Net";
  margin-top: 10px;
}

.DirectorQuickAccess .SalesSummary:hover {
  border: #359c8d 2px solid;
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
  -webkit-box-shadow: 0 5px 20px 5px #00000044;
          box-shadow: 0 5px 20px 5px #00000044;
}

.DirectorQuickAccess .SalesSummary h4 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: h4;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.DirectorQuickAccess .SalesSummary .totalOrders {
  border-top: 2px #9A9A9A solid;
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: totalOrders;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.DirectorQuickAccess .SalesSummary .totalOrders:hover {
  border-top: #359c8d 2px solid;
  color: #359c8d;
}

.DirectorQuickAccess .SalesSummary .totalOrders p {
  font-size: 80%;
  margin-bottom: 5px;
}

.DirectorQuickAccess .SalesSummary .TotalSales {
  border-top: 2px #9A9A9A solid;
  -ms-grid-row: 3;
  -ms-grid-column: 2;
  grid-area: TotalSales;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.DirectorQuickAccess .SalesSummary .TotalSales:hover {
  border-top: #359c8d 2px solid;
  color: #359c8d;
}

.DirectorQuickAccess .SalesSummary .TotalSales p {
  font-size: 80%;
  margin-bottom: 5px;
}

.DirectorQuickAccess .SalesSummary .Net {
  border-top: 2px #9A9A9A solid;
  -ms-grid-row: 3;
  -ms-grid-column: 3;
  grid-area: Net;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.DirectorQuickAccess .SalesSummary .Net:hover {
  border-top: #359c8d 2px solid;
  color: #359c8d;
}

.DirectorQuickAccess .SalesSummary .Net p {
  font-size: 80%;
  margin-bottom: 5px;
}

.DirectorDashboard {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: DirectorDashboard;
  width: 100%;
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr  1fr;
      grid-template-columns: 1fr 1fr 1fr  1fr;
  -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas: "three three three  three" "three three three  three" "two two two two" " four four four four" "six six six six" "five five five five";
  grid-gap: 20px;
}

.DirectorDashboard_Card {
  border-radius: 20px;
  height: 250px;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  padding: 15px;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.DirectorDashboard_Card.one {
  display: -ms-grid;
  display: grid;
  height: 80%;
  background-color: white;
  grid-area: one;
}

.DirectorDashboard_Card.two {
  height: 100%;
  width: 100%;
  max-height: 350px;
  margin: 15px;
  padding: 0;
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-area: two;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: minmax(25px, 50px) 1fr 1fr;
      grid-template-rows: minmax(25px, 50px) 1fr 1fr;
      grid-template-areas: "Title Title" "Content Content" "Content Content";
  background: rgba(255, 255, 255, 0.46);
  border-radius: 16px;
  -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.DirectorDashboard_Card.two .Title {
  border-radius: 20px 20px 0 0;
  border-bottom: 3px solid #359c8d;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: Title;
  display: -ms-grid;
  display: grid;
  text-align: center;
  place-items: center;
}

.DirectorDashboard_Card.two .Title p {
  font-size: 1rem;
  font-weight: 600;
}

.DirectorDashboard_Card.two a {
  color: #111111;
}

.DirectorDashboard_Card.two .Content {
  border-radius: 0 0 20px 20px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: Content;
      grid-template-rows: 1fr  1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr;
  margin: 0 auto;
}

.DirectorDashboard_Card.two .Content .Order_dashboard {
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  margin: 5px;
  border-radius: 5px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  text-align: left;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.DirectorDashboard_Card.two .Content .Order_dashboard:hover {
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  background-color: #359c8d;
  border-radius: 0;
  color: #f1f1f1;
  cursor: pointer;
}

.DirectorDashboard_Card.two .Content h1 {
  font-size: 2rem;
  font-weight: 600;
}

.DirectorDashboard_Card.three {
  height: 100%;
  background: rgba(255, 255, 255, 0.46);
  border-radius: 16px;
  -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-area: three;
  margin: 15px;
  padding: 0;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: minmax(25px, 50px) 1fr 1fr;
      grid-template-rows: minmax(25px, 50px) 1fr 1fr;
      grid-template-areas: "Title Title" "Content Content" "Content Content";
}

.DirectorDashboard_Card.three a {
  color: #111111;
}

.DirectorDashboard_Card.three .Title {
  border-radius: 20px 20px 0 0;
  border-bottom: 3px solid #359c8d;
  width: 100%;
  height: 100%;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: Title;
  display: -ms-grid;
  display: grid;
  text-align: center;
  place-items: center;
}

.DirectorDashboard_Card.three .Title p {
  font-size: 1rem;
  font-weight: 600;
}

.DirectorDashboard_Card.three .Content {
  border-radius: 0 0 20px 20px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: Content;
      grid-template-rows: 1fr  1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr;
  margin: 0 auto;
}

.DirectorDashboard_Card.three .Content .Order_dashboard {
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  margin: 5px;
  border-radius: 5px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  text-align: left;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.DirectorDashboard_Card.three .Content .Order_dashboard:hover {
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  background-color: #359c8d;
  cursor: pointer;
  color: #f1f1f1;
}

.DirectorDashboard_Card.three .Content h1 {
  font-size: 2rem;
  font-weight: 600;
}

.DirectorDashboard_Card.four {
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-area: four;
  margin: 15px;
  padding: 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: minmax(25px, 50px) 1fr 1fr;
      grid-template-rows: minmax(25px, 50px) 1fr 1fr;
      grid-template-areas: "Title Title" "Content Content" "Content Content";
  background: rgba(255, 255, 255, 0.46);
  border-radius: 16px;
  -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.DirectorDashboard_Card.four .Title {
  border-radius: 20px 20px 0 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: Title;
  display: -ms-grid;
  display: grid;
  text-align: center;
  place-items: center;
}

.DirectorDashboard_Card.four .Title p {
  font-size: 1rem;
  font-weight: 600;
}

.DirectorDashboard_Card.four a {
  color: #111111;
}

.DirectorDashboard_Card.four .Content {
  border-radius: 0 0 20px 20px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: Content;
  -ms-grid-rows: 1fr  1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr  1fr 1fr 1fr 1fr;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  margin: 0 auto;
}

.DirectorDashboard_Card.four .Content .Order_dashboard {
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  margin: 5px;
  border-radius: 5px;
  -ms-grid-columns: 2fr 1fr 1fr  1fr;
      grid-template-columns: 2fr 1fr 1fr  1fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  text-align: left;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.DirectorDashboard_Card.four .Content .Order_dashboard:hover {
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  background-color: #359c8d;
  border-radius: 0;
  color: #f1f1f1;
  cursor: pointer;
}

.DirectorDashboard_Card.four .Content h1 {
  font-size: 2rem;
  font-weight: 600;
}

.DirectorDashboard_Card.five {
  background-color: white;
  -ms-grid-row: 6;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-area: five;
}

.DirectorDashboard_Card.six {
  background-color: white;
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-area: six;
}

.SalesOrderPage {
  width: 98%;
  margin: 0 auto;
}

.SalesOrderPage ._Info {
  width: 80%;
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border: 1px solid #0a6887;
  margin: 15px auto;
  padding: 15px;
}

.SalesOrderPage ._Info .Customer {
  border-bottom: 2px solid #359c8d;
  grid-area: Customer;
}

.SalesOrderPage ._Info .Customer.bad {
  color: red;
  border-bottom: 2px solid red;
}

.SalesOrderPage ._Info .Customer.Good {
  color: #359c8d;
  border-bottom: 2px solid #359c8d;
}

.Order {
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border: 1px solid #0a6887;
  margin: 15px 0;
}

.Order .OrderItems {
  padding: 15px;
}

.StateName {
  margin: 10px auto;
  font-size: 14px;
  text-align: center;
  font-weight: 900;
}

.Orderinfo {
  width: 80%;
  margin: 15px auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 3px #0a6887 solid;
      grid-template-areas: "Vchseries Orderid All_Order_Items_Status ";
  font-size: 9px;
  font-weight: 600;
}

.Orderinfo .TaxType {
  grid-area: TaxType;
  border-bottom: 2px solid #0a6887;
}

.Orderinfo .Orderid {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: Orderid;
  margin: 0 auto;
  font-size: 14px;
}

.Orderinfo .Vchseries {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: Vchseries;
}

.Orderinfo .Created_DateTime {
  border-bottom: 2px solid #0a6887;
  grid-area: Created_DateTime;
}

.Orderinfo .Customer {
  border-bottom: 2px solid #359c8d;
  grid-area: Customer;
}

.Orderinfo .Customer.bad {
  color: red;
  border-bottom: 2px solid red;
}

.Orderinfo .Customer.Good {
  color: #359c8d;
  border-bottom: 2px solid #359c8d;
}

.Orderinfo .BrokerName {
  border-bottom: 2px solid #0a6887;
  grid-area: BrokerName;
}

.Orderinfo .GTotal {
  grid-area: GTotal;
  border-bottom: 2px solid #0a6887;
}

.Orderinfo .All_Order_Items_Status {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: All_Order_Items_Status;
}

.Line_Item_approvalstate {
  display: inline-block;
  position: relative;
}

select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  border: 1px solid #ccc;
  background-color: white;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}

select:focus {
  border-color: #359c8d;
}

select option:hover {
  background-color: #359c8d;
}

.Line_Item_approvalstate::after {
  content: '▼';
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  pointer-events: none;
  color: #359c8d;
}

@media screen and (min-width: 700px) {
  .Orderinfo {
    font-size: 15px;
  }
  .Orderinfo .Orderid {
    font-size: 18px;
  }
}

.StateName {
  color: #2b2b2b;
  font-size: 18px;
  padding: 5px;
  margin: 15px;
}

.Payment_Type_summery {
  color: #14185B;
  font-size: 18px;
  text-align: center;
  font-weight: 800;
  padding: 5px;
  text-decoration: underline;
}

.Payment_Type {
  color: #359c8d;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  border-bottom: 2px solid #359c8d;
  padding: 15px;
}

.Line_Item {
  border-bottom: 1px grey solid;
}

.InvoiceData {
  background-color: #e2e2e2;
  margin: 10px auto;
  width: 80%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
}

.Order_Items_Table {
  margin: 0 auto;
  width: 100%;
}

.Order_Items_Payment_mode {
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border: 3px solid #0a6887;
  width: 90%;
  margin: 15px auto;
}

.Order_Items_Credit_Period {
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  margin: 15px auto;
}

/*
.Line_Item{
    font-family: 'open sans';
    display: grid;
    background-color: rgb(255, 255, 255);
    width: 98%;
    grid-template-rows: 1fr  1fr ;
    grid-template-columns:.1fr 1fr 2fr .7fr .7fr;

   // border-radius: 5px;
   // border: 1px solid #359c8d36;
    margin: 0 auto;
    //padding: 5px;
    //font-weight: 600;
    font-size: 10px;
    grid-template-areas:
    " Name Name  Name  Name  Name "
    "List_Price Discount Price Qty  Amount";
    font-size: 9px;
    &_Brand{
       // border-radius: 5px;
       // box-shadow: $shadow;
    //  background-color: rgba(139, 7, 14, 0.144);
    //background-color: #63C7B2;
   // border:#73D884 solid 2px ;
    border:#40404052 solid 1px ;
    grid-area: Brand;
            
    }
    &_Supplier{
  //  background-color: rgba(132, 0, 255, 0.144);
  //border:#73D884 solid 2px ;
  border:#40404052 solid 1px ;
  grid-area: Supplier;    
        
    }
    &Line_Item_Checkbox{
        grid-area: Line_Item_Checkbox;
        display: grid;
        place-items: center;
        background-color: antiquewhite;
        margin: 0 auto;
    }
    &_approvalstate{
       // border-radius: 5px;
       // box-shadow: $shadow;
    grid-area: approvalstate;
    background-color: rgba(19, 130, 204, 0.548);
    margin: 0 auto;
    }

    &_Name{
       // border-radius: 5px;
       // box-shadow: $shadow;
    //background-color: rgba(255, 0, 13, 0.548);
   // background-color: #0a688775;
   // border:#0054fc solid 2px ;
    border:#40404052 solid 1px ;

    grid-area: Name;
    }

    &_ACode{
       // border-radius: 5px;
       // box-shadow: $shadow;
    grid-area: ACode;
    background-color: rgb(0, 17, 255);
    }

    &_Qty{
       // border-radius: 5px;
       // box-shadow: $shadow;
    grid-area: Qty;
 //   background-color: rgba(255, 0, 85, 0.144);
  //border:#ff20ff solid 2px ;
 border:#40404052 solid 1px ;
 text-align: right;
    padding: 2px 0;
    }

    &_List_Price{
       // border-radius: 5px;
       // box-shadow: $shadow;
      //   background-color: rgba(255, 153, 0, 0.733);
   // background-color: #8E6C88;
 //border:#825D5D solid 2px ;
 border:#40404052 solid 1px ;

    text-align: right;
    grid-area: List_Price;
    }

    &_Discount{
       // border-radius: 5px;
       // box-shadow: $shadow;
    grid-area: Discount;
    //  background-color: rgba(204, 169, 12, 0.144);
    //background-color: #80CED7;
 //border:#00bdfc solid 2px ;
 border:#40404052 solid 1px ;

    text-align: right;
    padding: 2px 0;
    }

    &_Profitablity{
       // border-radius: 5px;
       // box-shadow: $shadow;
    grid-area: Profitablity;
 //border:rgb(51, 255, 0) solid 2px ;
 border:#40404052 solid 1px ;

   // background-color: rgba(41, 177, 7, 0.596);
    text-align: right;
    padding: 2px 0;
    }

    &_Price{
       // border-radius: 5px;
       // box-shadow: $shadow;
    grid-area: Price;
      //  background-color: rgba(113, 219, 14, 0.144);
     //  background-color: #CCDBDC;
    text-align: right;
 //border:#6D6D6D solid 2px ;
 border:#40404052 solid 1px ;

 padding: 2px 0;
    }

    &_Amount{
       // border-radius: 5px;
       // box-shadow: $shadow;
    text-align: right;
    grid-area: Amount;
    padding: 2px 0;
  //  background-color: rgba(0, 255, 255, 0.144);
 //border:#005457 solid 2px ;
 border:#40404052 solid 1px ;

    }
    
    &_rowcount{
        grid-area: rowcount;
        text-align: right;
        padding: 2px 0;
    }
}*/
.Titles_Cart_Line_Item {
  font-family: 'open sans';
  display: -ms-grid;
  display: grid;
  background-color: #359c8d;
  color: white;
  width: 98%;
  -ms-grid-rows: 1fr  1fr 1fr;
      grid-template-rows: 1fr  1fr 1fr;
  -ms-grid-columns: .1fr 1fr 2fr .7fr .7fr;
      grid-template-columns: .1fr 1fr 2fr .7fr .7fr;
  margin: 0 auto;
  font-weight: 600;
  font-size: 10px;
      grid-template-areas: " Name Name  Name  Name  ACode " " Supplier Supplier Supplier   Brand Brand  " "Qty Discount List_Price   Price   Amount";
  margin: 0 auto;
}

.Titles_Cart_Line_Item_Brand {
  grid-area: Titles_Brand;
}

.Titles_Cart_Line_Item_Supplier {
  grid-area: Titles_Supplier;
}

.Titles_Cart_Line_Item_Name {
  grid-area: Titles_Name;
}

.Titles_Cart_Line_Item_ACode {
  grid-area: Titles_ACode;
}

.Titles_Cart_Line_Item_Qty {
  grid-area: Titles_Qty;
  text-align: right;
  padding: 2px 0;
}

.Titles_Cart_Line_Item_List_Price {
  text-align: right;
  grid-area: Titles_List_Price;
}

.Titles_Cart_Line_Item_Discount {
  grid-area: Titles_Discount;
  text-align: right;
  padding: 2px 0;
}

.Titles_Cart_Line_Item_Price {
  grid-area: Titles_Price;
  text-align: right;
  padding: 2px 0;
}

.Titles_Cart_Line_Item .Titles_Cart_Line_Remove {
  text-align: center;
  grid-area: Titles_Remove;
}

.Titles_Cart_Line_Item_Amount {
  text-align: right;
  grid-area: Titles_Amount;
  padding: 2px 0;
}

.Cart_Line_Item {
  display: -ms-grid;
  display: grid;
  width: 98%;
  -ms-grid-rows: 1fr  1fr 1fr;
      grid-template-rows: 1fr  1fr 1fr;
  -ms-grid-columns: .1fr 1fr 2fr .7fr .7fr;
      grid-template-columns: .1fr 1fr 2fr .7fr .7fr;
  margin: 0 auto;
  font-weight: 600;
  font-size: 10px;
      grid-template-areas: " Name Name  Name  Name  ACode " " Supplier Supplier Supplier   Brand   Brand" "Qty Discount List_Price   Price   Amount";
  font-size: 9px;
  margin: 0 auto;
}

.Cart_Line_Item_Brand {
  -ms-grid-row: 2;
  -ms-grid-column: 4;
  -ms-grid-column-span: 2;
  grid-area: Brand;
}

.Cart_Line_Item_Supplier {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: Supplier;
}

.Cart_Line_Item_Name {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-area: Name;
}

.Cart_Line_Item_ACode {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
  grid-area: ACode;
}

.Cart_Line_Item_Qty {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: Qty;
  text-align: right;
  padding: 2px 0;
}

.Cart_Line_Item_List_Price {
  text-align: right;
  -ms-grid-row: 3;
  -ms-grid-column: 3;
  grid-area: List_Price;
}

.Cart_Line_Item_Discount {
  -ms-grid-row: 3;
  -ms-grid-column: 2;
  grid-area: Discount;
  text-align: right;
  padding: 2px 0;
}

.Cart_Line_Item_Price {
  -ms-grid-row: 3;
  -ms-grid-column: 4;
  grid-area: Price;
  text-align: right;
  padding: 2px 0;
}

.Cart_Line_Item_Amount {
  text-align: right;
  -ms-grid-row: 3;
  -ms-grid-column: 5;
  grid-area: Amount;
  padding: 2px 0;
}

@media screen and (min-width: 700px) {
  .Line_Item {
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    -ms-grid-columns: 0.1fr 3fr 1fr 0.5fr 1fr 1fr 1fr 0.5fr minmax(250px, 1fr);
        grid-template-columns: 0.1fr 3fr 1fr 0.5fr 1fr 1fr 1fr 0.5fr minmax(250px, 1fr);
    font-size: 17px;
        grid-template-areas: "rowcount Name Brand  Qty List_Price  Discount Price  Amount Profitablity approvalstate";
  }
  .Titles_Cart_Line_Item {
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    -ms-grid-columns: 2fr   1fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr  1fr;
        grid-template-columns: 2fr   1fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr  1fr;
    font-size: 17px;
        grid-template-areas: "Titles_Supplier Titles_ACode Titles_Name Titles_Brand Titles_Qty Titles_List_Price Titles_Discount Titles_Price Titles_Amount Titles_Remove";
  }
  .Cart_Line_Item {
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    -ms-grid-columns: 2fr   1fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 2fr   1fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr;
    font-size: 17px;
        grid-template-areas: "Supplier ACode Name Brand Qty List_Price Discount Price Amount";
  }
}

.line-product-summary-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.line-product-summary-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.line-product-summary-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.invoicetable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  margin: 0 auto;
}

.invoicetable thead {
  background-color: #f5f5f5;
}

.invoicetable thead tr th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.invoicetable tbody tr td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  text-align: left;
}

.invoicetable tfoot tr td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  text-align: left;
}

.Product_datachart {
  width: 50%;
}

.Product_Stock_Info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

table {
  border-collapse: collapse;
  width: 80%;
}

thead {
  background-color: #ddd;
}

th,
td {
  text-align: left;
  padding: 8px;
}

/*
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }*/
tr:hover {
  background-color: #ddd;
}

@media (max-width: 600px) {
  table {
    font-size: 12px;
  }
}

.in-stock {
  background-color: #0a68877a;
  color: black;
}

.out-stock {
  background-color: #25d44233;
  color: black;
}

.register {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 30px;
  background-color: white;
}

@media screen and (min-width: 1366px) {
  .register {
    padding: 0;
  }
}

.register h1 {
  line-height: 100%;
  color: #2b2b2b;
  margin-bottom: 40px;
}

.register-progressbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-bottom: 40px;
  counter-reset: step;
}

@media screen and (min-width: 1366px) {
  .register-progressbar {
    width: 560px;
  }
}

.register-progressbar li {
  position: relative;
  text-align: center;
  list-style: none;
  color: white;
  margin: 0 10px;
}

@media screen and (min-width: 1366px) {
  .register-progressbar li {
    margin: 0 15px;
    font-size: 20px;
  }
}

.register-progressbar li:nth-of-type(2)::after {
  content: "";
  position: absolute;
  left: -30px;
  top: 50%;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  z-index: -1;
  width: 30px;
  height: 3px;
  background: #9A9A9A;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.register-progressbar li:nth-of-type(2).active::after {
  background: #0a6887;
}

.register-progressbar li::before {
  background-color: #0a6887;
  content: counter(step);
  counter-increment: step;
  border: 2px solid #9A9A9A;
  border-radius: 100%;
  color: #9A9A9A;
  background: white;
  display: block;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  width: 30px;
  line-height: 30px;
}

@media screen and (min-width: 1366px) {
  .register-progressbar li::before {
    width: 45px;
    line-height: 45px;
  }
}

.register-progressbar li.active::before {
  border: 2px solid #0a6887;
  color: white;
  background: #0a6887;
}

.register-form {
  position: relative;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .register-form {
    margin-bottom: 75px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .register-form {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1366px) {
  .register-form {
    margin-bottom: 125px;
  }
}

@media screen and (max-width: 480px) {
  .register-form-wrapper {
    width: 100%;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .register-form-wrapper {
    width: 85%;
  }
}

@media screen and (min-width: 1366px) {
  .register-form-wrapper {
    width: 560px;
  }
}

.register-form .inline-btns input[type=submit],
.register-form .inline-btns input[type=button] {
  color: white;
  width: 50%;
  background-color: #0a6887;
  border: 1px solid #0a6887;
  margin-top: 75px;
}

@media screen and (max-width: 480px) {
  .register-form .inline-btns input[type=submit],
  .register-form .inline-btns input[type=button] {
    font-size: 16px;
    padding: 10px 0;
    margin-top: 15px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .register-form .inline-btns input[type=submit],
  .register-form .inline-btns input[type=button] {
    font-size: 18px;
    padding: 10px 0;
  }
}

@media screen and (min-width: 1366px) {
  .register-form .inline-btns input[type=submit],
  .register-form .inline-btns input[type=button] {
    font-size: 20px;
    padding: 17.5px 0;
    border-radius: 20px;
  }
}

.register-form .inline-btns input[type=submit]#register-prev,
.register-form .inline-btns input[type=button]#register-prev {
  color: #0a6887;
  background-color: white;
  border: 1px solid transparent;
  margin-right: 30px;
}

.register-form .inline-btns input[type=submit]#register-prev:hover,
.register-form .inline-btns input[type=button]#register-prev:hover {
  border: 1px solid #0a6887;
}

@media screen and (max-width: 480px) {
  .register-form .inline-btns input[type=submit]#register-prev,
  .register-form .inline-btns input[type=button]#register-prev {
    margin-right: 0;
  }
}

.register-form fieldset {
  border-radius: 30px;
  padding: 30px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  background-color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  width: 100%;
}

@media screen and (min-width: 1366px) {
  .register-form fieldset {
    width: 560px;
    padding: 60px;
  }
}

.register-form fieldset.inactive {
  visibility: hidden;
  opacity: 0%;
  left: 100%;
}

@media screen and (min-width: 1366px) {
  .register-form fieldset.inactive {
    left: 50%;
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
  }
}

.register-form fieldset.active {
  visibility: visible;
  opacity: 100%;
  left: 0%;
}

.register-form fieldset.completed {
  opacity: 0%;
}

@media screen and (min-width: 1366px) {
  .register-form fieldset.completed {
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
  }
}

.register-form fieldset h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #2b2b2b;
}

@media screen and (min-width: 1366px) {
  .register-form fieldset h2 {
    margin-bottom: 50px;
  }
}

.register-form fieldset:nth-of-type(1) h2 {
  margin-bottom: 15px;
}

@media screen and (min-width: 1366px) {
  .register-form fieldset:nth-of-type(1) h2 {
    margin-bottom: 25px;
  }
}

.register-form fieldset .floating-input {
  margin-bottom: 30px;
}

@media screen and (max-width: 480px) {
  .register-form fieldset .floating-input {
    margin-bottom: 20px;
  }
}

.login {
  width: 100%;
  display: -ms-grid;
  display: grid;
  /* flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    */
  place-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 30px;
  position: absolute;
  background-color: white;
}

@media screen and (min-width: 1366px) {
  .login {
    padding: 0;
  }
}

.login h1 {
  line-height: 100%;
  color: #0a6887;
  margin-bottom: 40px;
}

.login-form {
  position: relative;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .login-form {
    margin-bottom: 75px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .login-form {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1366px) {
  .login-form {
    margin-bottom: 125px;
  }
}

@media screen and (max-width: 480px) {
  .login-form-wrapper {
    width: 100%;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .login-form-wrapper {
    width: 75%;
  }
}

@media screen and (min-width: 1366px) {
  .login-form-wrapper {
    width: 450px;
  }
}

.login-form .inline-btns input[type=submit],
.login-form .inline-btns input[type=button] {
  color: white;
  width: 50%;
  background-color: #0a6887;
  border: 1px solid #0a6887;
  margin-top: 30px;
}

@media screen and (max-width: 480px) {
  .login-form .inline-btns input[type=submit],
  .login-form .inline-btns input[type=button] {
    font-size: 16px;
    padding: 10px 0;
    margin-top: 15px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .login-form .inline-btns input[type=submit],
  .login-form .inline-btns input[type=button] {
    font-size: 18px;
    padding: 10px 0;
  }
}

@media screen and (min-width: 1366px) {
  .login-form .inline-btns input[type=submit],
  .login-form .inline-btns input[type=button] {
    font-size: 20px;
    padding: 18px 0;
  }
}

.login-form fieldset {
  border-radius: 25px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  background-color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .login-form fieldset {
    padding: 30px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .login-form fieldset {
    padding: 30px 45px;
  }
}

@media screen and (min-width: 1366px) {
  .login-form fieldset {
    padding: 40px;
  }
}

.login-form fieldset .floating-input {
  margin-bottom: 30px;
}

@media screen and (max-width: 480px) {
  .login-form fieldset .floating-input {
    margin-bottom: 20px;
  }
}

.login-form-forgot {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 90%;
  font-weight: 500;
  text-decoration: underline;
  color: blue !important;
}

.reset,
.forgot {
  width: 100%;
  display: -ms-grid;
  display: grid;
  /*  flex-direction: column;
    justify-content: flex-start;*/
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  place-content: center;
  padding: 0 30px;
  position: absolute;
  /* top: 50%;
    transform: translateY(-150%);
*/
  background-color: white;
}

@media screen and (min-width: 1366px) {
  .reset,
  .forgot {
    padding: 0;
  }
}

.reset h1,
.forgot h1 {
  line-height: 100%;
  color: #0a6887;
  margin-bottom: 40px;
}

.reset-form,
.forgot-form {
  position: relative;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .reset-form,
  .forgot-form {
    margin-bottom: 75px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .reset-form,
  .forgot-form {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1366px) {
  .reset-form,
  .forgot-form {
    margin-bottom: 125px;
  }
}

@media screen and (max-width: 480px) {
  .reset-form-wrapper,
  .forgot-form-wrapper {
    width: 100%;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .reset-form-wrapper,
  .forgot-form-wrapper {
    width: 75%;
  }
}

@media screen and (min-width: 1366px) {
  .reset-form-wrapper,
  .forgot-form-wrapper {
    width: 450px;
  }
}

@media screen and (max-width: 480px) {
  .reset-form .stacked-btns input[type=submit],
  .reset-form .stacked-btns input[type=button],
  .forgot-form .stacked-btns input[type=submit],
  .forgot-form .stacked-btns input[type=button] {
    font-size: 16px;
    padding: 10px 0;
    margin-top: 15px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .reset-form .stacked-btns input[type=submit],
  .reset-form .stacked-btns input[type=button],
  .forgot-form .stacked-btns input[type=submit],
  .forgot-form .stacked-btns input[type=button] {
    font-size: 18px;
    padding: 10px 0;
  }
}

@media screen and (min-width: 1366px) {
  .reset-form .stacked-btns input[type=submit],
  .reset-form .stacked-btns input[type=button],
  .forgot-form .stacked-btns input[type=submit],
  .forgot-form .stacked-btns input[type=button] {
    font-size: 20px;
    padding: 18px 0;
  }
}

.reset-form .stacked-btns input[type=submit],
.forgot-form .stacked-btns input[type=submit] {
  color: white;
  border: 1px solid #0a6887;
  background-color: #0a6887;
}

.reset-form .stacked-btns input[type=button],
.forgot-form .stacked-btns input[type=button] {
  color: #0a6887;
  background-color: white;
}

.reset-form fieldset,
.forgot-form fieldset {
  border-radius: 25px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  background-color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .reset-form fieldset,
  .forgot-form fieldset {
    padding: 30px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .reset-form fieldset,
  .forgot-form fieldset {
    padding: 30px 45px;
  }
}

@media screen and (min-width: 1366px) {
  .reset-form fieldset,
  .forgot-form fieldset {
    padding: 40px;
  }
}

.reset-form a,
.forgot-form a {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 90%;
  font-weight: 500;
  text-decoration: underline;
  color: blue !important;
}

.dash {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
  width: 100%;
  height: inherit;
  overflow: hidden;
}

.dash-nav {
  height: 100%;
  width: 70px;
  overflow: hidden auto;
  background-color: #2b2b2b;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .dash-nav {
    width: 50px;
  }
}

@media screen and (max-width: 480px) {
  .dash-nav.open {
    width: 70%;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .dash-nav.open {
    width: 25%;
  }
}

@media screen and (min-width: 1366px) {
  .dash-nav.open {
    width: 250px;
  }
}

.dash-nav.open .dash-nav-menu a {
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

@media screen and (max-width: 480px) {
  .dash-nav.open .dash-nav-menu a {
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }
}

.dash-nav.open .dash-nav-menu a svg {
  -webkit-transition: fill .2s ease-in-out, margin-right .2s ease-in-out 0s;
  transition: fill .2s ease-in-out, margin-right .2s ease-in-out 0s;
}

.dash-nav.open .dash-nav-toggle {
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

@media screen and (max-width: 480px) {
  .dash-nav.open .dash-nav-toggle {
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }
}

.dash-nav.open .dash-nav-toggle svg {
  -webkit-transition: fill .2s ease-in-out, margin-right .2s ease-in-out 0s, -webkit-transform .2s ease-in-out;
  transition: fill .2s ease-in-out, margin-right .2s ease-in-out 0s, -webkit-transform .2s ease-in-out;
  transition: fill .2s ease-in-out, transform .2s ease-in-out, margin-right .2s ease-in-out 0s;
  transition: fill .2s ease-in-out, transform .2s ease-in-out, margin-right .2s ease-in-out 0s, -webkit-transform .2s ease-in-out;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.dash-nav a,
.dash-nav button {
  display: block;
  white-space: nowrap;
}

@media screen and (max-width: 480px) {
  .dash-nav a,
  .dash-nav button {
    width: 70vw;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .dash-nav a,
  .dash-nav button {
    width: 25vw;
  }
}

@media screen and (min-width: 1366px) {
  .dash-nav a,
  .dash-nav button {
    width: 250px;
  }
}

.dash-nav-menu {
  width: 100%;
}

.dash-nav-menu a {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 4fr;
      grid-template-columns: 1fr 4fr;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 25px;
          column-gap: 25px;
  font-size: 18px;
  color: white;
  font-weight: 500;
  line-height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  padding: 15px 20px;
  z-index: 2;
}

@media screen and (max-width: 480px) {
  .dash-nav-menu a {
    font-size: 16px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    padding: 15px 12.5px;
  }
}

.dash-nav-menu a:hover, .dash-nav-menu a.active {
  color: #4952a3;
}

.dash-nav-menu a:hover svg, .dash-nav-menu a.active svg {
  fill: #4952a3;
}

.dash-nav-menu a:hover::before, .dash-nav-menu a.active::before {
  left: 0;
}

.dash-nav-menu a::before {
  content: "";
  position: absolute;
  background-color: white;
  bottom: 0;
  left: 100%;
  right: 0;
  top: 0;
  z-index: -1;
  -webkit-transition: left .5s ease-out;
  transition: left .5s ease-out;
}

.dash-nav-menu a.active::before {
  left: 0;
}

.dash-nav-menu a svg {
  fill: white;
  -webkit-transition: fill .2s ease-in-out, margin-right .2s ease-in-out .2s;
  transition: fill .2s ease-in-out, margin-right .2s ease-in-out .2s;
  display: inline;
  z-index: 1;
  width: 28px;
}

@media screen and (max-width: 480px) {
  .dash-nav-menu a svg {
    width: 24px;
  }
}

.dash-nav-toggle {
  display: -ms-grid !important;
  display: grid !important;
  -ms-grid-columns: 1fr 4fr;
      grid-template-columns: 1fr 4fr;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 25px;
          column-gap: 25px;
  font-size: 18px;
  color: white;
  font-weight: 500;
  line-height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  padding: 15px 25px;
  text-align: start;
}

@media screen and (max-width: 480px) {
  .dash-nav-toggle {
    font-size: 16px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    padding: 15px 12.5px;
  }
}

.dash-nav-toggle:hover, .dash-nav-toggle.active {
  color: #4952a3;
}

.dash-nav-toggle:hover svg, .dash-nav-toggle.active svg {
  fill: #4952a3;
}

.dash-nav-toggle.active {
  background-color: white;
}

.dash-nav-toggle svg {
  fill: white;
  width: 28px;
  -webkit-transition: fill .2s ease-in-out, margin-right .2s ease-in-out .2s, -webkit-transform .2s ease-in-out;
  transition: fill .2s ease-in-out, margin-right .2s ease-in-out .2s, -webkit-transform .2s ease-in-out;
  transition: fill .2s ease-in-out, transform .2s ease-in-out, margin-right .2s ease-in-out .2s;
  transition: fill .2s ease-in-out, transform .2s ease-in-out, margin-right .2s ease-in-out .2s, -webkit-transform .2s ease-in-out;
  display: inline;
}

@media screen and (max-width: 480px) {
  .dash-nav-toggle svg {
    width: 24px;
  }
}

.dash-content {
  position: absolute;
  top: 0%;
  right: 0%;
  overflow-y: auto;
  height: 100%;
  width: calc(100% - 70px);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: white;
}

@media screen and (max-width: 480px) {
  .dash-content {
    padding: 15px;
    width: calc(100% - 50px);
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .dash-content {
    padding: 25px 40px;
  }
}

@media screen and (min-width: 1366px) {
  .dash-content {
    padding: 25px 80px;
  }
}

@media screen and (max-width: 480px) {
  .dash-content.open {
    right: calc(-70% + 50px);
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .dash-content.open {
    right: calc(-25% + 70px);
  }
}

@media screen and (min-width: 1366px) {
  .dash-content.open {
    right: calc(-250px + 70px);
  }
}

.dash-content .dash-section {
  width: 100%;
}

.dash-content .dash-section-title {
  color: #2b2b2b;
  text-align: start;
  line-height: 100%;
  margin-bottom: 30px;
}

@media screen and (min-width: 481px) {
  .dash-content .dash-section-title {
    margin-bottom: 50px;
  }
}

.dash-content .dash-section-nav {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  -webkit-column-gap: 75px;
          column-gap: 75px;
  row-gap: 50px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  padding: 0 100px;
}

@media screen and (max-width: 1365px) {
  .dash-content .dash-section-nav {
    padding: 0px;
    -ms-grid-columns: unset;
        grid-template-columns: unset;
    -ms-grid-rows: (1fr)[2];
        grid-template-rows: repeat(2, 1fr);
    row-gap: 30px;
  }
}

.dash-content .dash-section-nav-title {
  padding: 0 75px;
  margin-bottom: 50px;
}

@media screen and (max-width: 1365px) {
  .dash-content .dash-section-nav-title {
    padding: 0px;
    margin-bottom: 30px;
  }
}

.dash-content .dash-section-nav-link {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 50px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  color: #0a6887;
  border: 1px solid #0a6887;
  border-radius: 25px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  font-size: 175%;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

@media screen and (max-width: 1365px) {
  .dash-content .dash-section-nav-link {
    font-size: 150%;
    padding: 30px;
  }
}

.dash-content .dash-section-nav-link:hover {
  background-color: #0a6887;
  color: white;
}

.dash-content .dash-section-nav-link:hover svg {
  fill: white;
}

.dash-content .dash-section-nav-link svg {
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  fill: #0a6887;
  height: auto;
  margin-left: 50px;
}

@media screen and (min-width: 1366px) {
  .dash-content .dash-section-nav.home-nav {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
  }
}

.dash-content .dash-section-search {
  width: 100%;
  margin-bottom: 25px;
}

.dash-content .dash-section-search-input {
  position: relative;
}

.dash-content .dash-section-search-input input {
  width: 100%;
}

.dash-content .dash-section-search-input input:focus ~ button {
  border-left-color: #0a6887;
}

.dash-content .dash-section-search-input button {
  position: absolute;
  top: 0%;
  height: 100%;
  padding: 10px 15px;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.dash-content .dash-section-search-input button:nth-of-type(1) {
  right: 0%;
  border-radius: 0 15px 15px 0;
  border: 1px solid #2b2b2b;
  border-left-width: 1px;
  background-color: #2b2b2b;
}

.dash-content .dash-section-search-input button:nth-of-type(1):focus svg, .dash-content .dash-section-search-input button:nth-of-type(1):hover svg {
  fill: #4952a3;
}

.dash-content .dash-section-search-input button:nth-of-type(1) svg {
  fill: #f1f1f1;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.dash-content .dash-section-search-input button:nth-of-type(2) {
  right: 54px;
}

.dash-content .dash-section-search-input button:nth-of-type(2):hover svg {
  fill: #f85149;
}

.dash-content .dash-section-search-input button:nth-of-type(2) svg {
  fill: #2b2b2b;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  height: 15px;
}

.dash-content .dash-section-search-req {
  color: #2b2b2b;
  font-size: 90%;
  font-weight: 400;
  line-height: 100%;
  display: block;
  margin: 10px 0 0 10px;
}

@media screen and (max-width: 480px) {
  .dash-content .dash-section-btns {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto;
        grid-template-columns: auto;
    width: 100%;
  }
}

@media screen and (max-width: 1365px) {
  .dash-content .dash-section-btns {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1366px) {
  .dash-content .dash-section-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    position: absolute;
    right: 100px;
  }
}

.dash-content .dash-section-btns-btn {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 15px auto;
      grid-template-columns: 15px auto;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #0a6887;
  background-color: #0a6887;
  border-radius: 15px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 100%;
  padding: 15px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  color: white;
}

@media screen and (min-width: 481px) {
  .dash-content .dash-section-btns-btn {
    -ms-grid-columns: 15px auto;
        grid-template-columns: 15px auto;
  }
}

@media screen and (min-width: 1366px) {
  .dash-content .dash-section-btns-btn:not(:last-of-type) {
    margin-left: 10px;
  }
}

@media screen and (max-width: 480px) {
  .dash-content .dash-section-btns-btn {
    padding: 12.5px;
    width: auto;
  }
}

.dash-content .dash-section-btns-btn svg {
  height: 15px;
  fill: white;
}

.dash-content .dash-section-btns-btn:hover {
  opacity: .9;
}

.dash-content .dash-section-sort, .dash-content .dash-section-filter {
  z-index: 999;
  position: fixed;
  top: 0%;
  right: 0%;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  width: 25%;
  height: 100%;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: white;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border-radius: 0px;
  padding: 30px;
  -webkit-transition: -webkit-transform .3s ease-in-out;
  transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

@media screen and (max-width: 480px) {
  .dash-content .dash-section-sort, .dash-content .dash-section-filter {
    width: 100%;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .dash-content .dash-section-sort, .dash-content .dash-section-filter {
    width: 50%;
  }
}

.dash-content .dash-section-sort.open, .dash-content .dash-section-filter.open {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.dash-content .dash-section-sort-top, .dash-content .dash-section-filter-top {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #9A9A9A;
}

.dash-content .dash-section-sort-top button, .dash-content .dash-section-filter-top button {
  padding: 0;
}

.dash-content .dash-section-sort-top button svg, .dash-content .dash-section-filter-top button svg {
  fill: #2b2b2b;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  height: 17.5px;
}

.dash-content .dash-section-sort-top h3, .dash-content .dash-section-filter-top h3 {
  display: inline;
}

.dash-content .dash-section-sort p, .dash-content .dash-section-filter p {
  font-weight: 500;
  line-height: 100%;
  font-size: 16px;
  margin-bottom: 5px;
}

.dash-content .dash-section-sort-filters-single:not(:first-of-type), .dash-content .dash-section-filter-filters-single:not(:first-of-type) {
  margin-top: 15px;
}

.dash-content .dash-section-sort-filters-single-items, .dash-content .dash-section-filter-filters-single-items {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.dash-content .dash-section-sort-filters-single-item, .dash-content .dash-section-filter-filters-single-item {
  display: inline;
}

.dash-content .dash-section-sort-filters-single-item input, .dash-content .dash-section-filter-filters-single-item input {
  margin-right: 5px;
}

.dash-content .dash-section-sort-filters-single-item label, .dash-content .dash-section-filter-filters-single-item label {
  margin-right: 15px;
}

.dash-content .dash-section-sort-items, .dash-content .dash-section-filter-items {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.dash-content .dash-section-view {
  overflow: auto hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 20px;
}

@media screen and (max-width: 1365px) {
  .dash-content .dash-section-view {
    padding: 0;
  }
}

@media screen and (min-width: 1366px) {
  .dash-content .dash-section-view {
    padding: 5px 20px 0;
  }
}

.dash-content .dash-section-view-btns {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  border-radius: 15px 15px 0 0;
  background-color: #2b2b2b;
}

.dash-content .dash-section-view-btns.empty {
  border-radius: 15px;
}

.dash-content .dash-section-view-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 15px 20px;
}

.dash-content .dash-section-view-btns button svg {
  height: 24px;
  width: auto;
  fill: white;
}

@media screen and (max-width: 480px) {
  .dash-content .dash-section-view-btns button {
    padding: 15px;
  }
  .dash-content .dash-section-view-btns button svg {
    height: 20px;
  }
}

.dash-content .dash-section-view-btns-sortbtn svg {
  stroke: white;
  stroke-width: .5px;
}

.dash-content .dash-section-view-btns-orderbtn.desc svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.dash-content .dash-section-view table {
  width: 100%;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  border-collapse: separate !important;
  border-spacing: 0;
  border-radius: 0 15px 15px 15px;
  overflow: hidden;
}

.dash-content .dash-section-view table thead {
  color: #f1f1f1;
  background-color: #2b2b2b;
  width: 100%;
}

.dash-content .dash-section-view table thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 20px 30px;
}

@media screen and (max-width: 480px) {
  .dash-content .dash-section-view table thead th {
    padding: 20px 10px;
  }
}

.dash-content .dash-section-view table tbody {
  width: 100%;
}

.dash-content .dash-section-view table tbody tr {
  background-color: white;
  opacity: .9;
  -webkit-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
}

.dash-content .dash-section-view table tbody tr:hover {
  opacity: 1;
}

.dash-content .dash-section-view table tbody tr td {
  color: #2b2b2b;
  padding: 20px 30px;
}

@media screen and (max-width: 480px) {
  .dash-content .dash-section-view table tbody tr td {
    padding: 10px;
  }
}

.dash-content .dash-section-view table tbody tr td a {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 100%;
  padding: 10px 15px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  color: #0a6887;
}

.dash-content .dash-section-view table tbody tr td a:hover {
  color: #4952a3;
}

.dash-content .dash-section-view table.outbound-table tbody {
  width: 100%;
}

.dash-content .dash-section-view table.outbound-table tbody tr td:last-of-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dash-content .dash-section-view table.outbound-table tbody tr td:last-of-type input[type="button"] {
  display: block;
  font-weight: 500;
  line-height: 100%;
  padding: 15px 20px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 100%;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.dash-content .dash-section-view table.outbound-table tbody tr td:last-of-type input[type="button"]:nth-of-type(1) {
  border: 1px solid #0a6887;
  background-color: white;
  color: #0a6887;
  margin-right: 10px;
}

.dash-content .dash-section-view table.outbound-table tbody tr td:last-of-type input[type="button"]:nth-of-type(2) {
  border: 1px solid #f85149;
  background-color: #fdf7f7;
  color: #f85149;
}

.dash-content .dash-section-view-loadmore {
  width: 100%;
  padding: 12.5px 15px;
  margin-top: 20px;
  border-radius: 15px;
  border: 1px solid #0a6887;
  background-color: #0a6887;
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.dash-content .dash-section-view-loadmore:hover {
  opacity: .9;
}

.dash-content .dash-section-view-msg {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  padding: 10px 20px;
  margin-top: 20px;
  color: #9A9A9A;
  font-weight: 500;
  text-align: center;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.dash-content .dash-section-form {
  position: absolute;
  bottom: 0%;
  left: 0%;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.dash-content .dash-section-form-top {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dash-content .dash-section-form-top button {
  padding: 30px;
}

@media screen and (max-width: 480px) {
  .dash-content .dash-section-form-top button {
    padding: 20px;
  }
}

.dash-content .dash-section-form-top button:hover svg {
  opacity: .9;
}

.dash-content .dash-section-form-top button svg {
  fill: #2b2b2b;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.dash-content .dash-section-form-top h2 {
  display: inline;
}

.dash-content .dash-section-form-content {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 30px 75px;
  /*form{
                        border-radius: 20px;
                        background-color: white;
                        box-shadow: $shadow;
                        
                        padding: 50px;
                        
                        @media screen and (max-width: $mobileMax) {
                            padding: 30px;
                        }
                    }*/
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .dash-content .dash-section-form-content {
    padding: 30px 50px;
  }
}

@media screen and (max-width: 480px) {
  .dash-content .dash-section-form-content {
    padding: 30px;
  }
}

.dash-content .dash-section-form-content h3 {
  margin-bottom: 25px;
  line-height: 100%;
}

.dash-content .dash-section-form-content .labeled-input {
  margin-bottom: 30px;
}

@media screen and (max-width: 480px) {
  .dash-content .dash-section-form-content .labeled-input {
    margin-bottom: 20px;
  }
}

.dash-content .dash-section-form-btns {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  padding: 15px 75px 100px;
}

@media screen and (max-width: 480px) {
  .dash-content .dash-section-form-btns {
    padding: 10px 30px 40px;
  }
}

.dash-content .dash-section-form-btns button,
.dash-content .dash-section-form-btns input[type=submit],
.dash-content .dash-section-form-btns input[type=button] {
  font-size: 95%;
  padding: 15px 20px;
}

@media screen and (max-width: 480px) {
  .dash-content .dash-section-form-btns button,
  .dash-content .dash-section-form-btns input[type=submit],
  .dash-content .dash-section-form-btns input[type=button] {
    width: 100%;
  }
}

.dash-content .dash-section-form-danger {
  display: -ms-grid;
  display: grid;
  row-gap: 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 75%;
  padding: 25px 40px;
  margin: 75px auto 100px;
  border: 1px solid #f85149;
  border-radius: 20px;
}

@media screen and (max-width: 1365px) {
  .dash-content .dash-section-form-danger {
    width: 100%;
    padding: 20px 20px;
    margin: 50px auto;
  }
}

.dash-content .dash-section-form-danger button,
.dash-content .dash-section-form-danger input[type=submit],
.dash-content .dash-section-form-danger input[type=button] {
  font-size: 95%;
  padding: 15px 20px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin: auto;
}

@media screen and (max-width: 480px) {
  .dash-content .dash-section-form-danger button,
  .dash-content .dash-section-form-danger input[type=submit],
  .dash-content .dash-section-form-danger input[type=button] {
    width: 100%;
  }
}

.dash-content .dash-section-form-danger-section {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2.5fr 1fr;
      grid-template-columns: 2.5fr 1fr;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.dash-content .dash-section-form-danger-section input {
  width: 100%;
}

@media screen and (max-width: 1365px) {
  .dash-content .dash-section-form-danger-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .dash-content .dash-section-form-danger-section div {
    margin-bottom: 10px;
  }
}

.dash-content .dash-section-form .inline-btns {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

@media screen and (min-width: 1366px) {
  .dash-content .dash-section-form .inline-btns input {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
  }
}

.dash-content .dash-section-form-addbtn, .dash-content .dash-section-form-editbtn, .dash-content .dash-section-form-greenbtn {
  background-color: #0a6887;
  color: white;
  border: 1px solid #0a6887 !important;
}

.dash-content .dash-section-form-cancelbtn, .dash-content .dash-section-form-redbtn {
  background-color: transparent;
  color: #f85149;
  border: 1px solid #f85149 !important;
}

@media screen and (min-width: 481px) {
  .dash-content .dash-section-form-cancelbtn, .dash-content .dash-section-form-redbtn {
    margin-right: 15px;
  }
}

.dash-content .dash-section-form-disablebtn, .dash-content .dash-section-form-deletebtn {
  background-color: transparent;
  color: #f85149;
  border: 1px solid #f85149 !important;
}

@media screen and (min-width: 481px) {
  .dash-content .dash-section-form-disablebtn, .dash-content .dash-section-form-deletebtn {
    margin-right: 15px;
  }
}

.dash-content .dash-section-form-disablebtn:hover, .dash-content .dash-section-form-deletebtn:hover {
  color: white;
  background-color: #f85149;
}

.dash-content .dash-section-form-enablebtn {
  background-color: transparent;
  color: #0a6887;
  border: 1px solid #0a6887 !important;
}

@media screen and (min-width: 481px) {
  .dash-content .dash-section-form-enablebtn {
    margin-right: 15px;
  }
}

.dash-content .dash-section-form-enablebtn:hover {
  color: white;
  background-color: #0a6887;
}

.dash-details-section {
  border-radius: 20px;
  background-color: white;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  padding: 50px;
  margin-bottom: 35px;
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .dash-details-section {
    padding: 35px;
  }
}

@media screen and (max-width: 480px) {
  .dash-details-section {
    padding: 30px;
  }
}

.CategoryPageWrapper {
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
}

@media screen and (max-width: 480px) {
  .CategoryPageWrapper {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 481px) and (max-width: 1365px) {
  .CategoryPageWrapper {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 1366px) {
  .CategoryPageWrapper {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
}

.groupProductview {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin: 0 auto;
  display: grid;
}

.groupProductview .CategoryInformation {
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
  /* adjust this value to your header height */
  z-index: 15;
  text-align: center;
  margin-bottom: 25px;
  display: -ms-grid;
  display: grid;
  font-size: 15px;
  font-weight: 600;
  background-color: #0a6887;
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  color: #0a6887;
  background: rgba(255, 255, 255, 0.25);
  -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
          box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.groupProductview .CategoryInformation .Name {
  text-align: center;
}

.groupProductview .CategoryInformation .itemcount {
  text-align: center;
}

.groupProductview .ProductScroll {
  margin: 4px, 4px;
  padding: 4px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

.groupProductview .CryptoCard {
  margin: 0 auto;
  background-color: #0a6887;
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  font-family: 'Poppins', sans-serif;
}

.groupProductview .CategoryPageWrapper {
  display: -ms-grid;
  display: grid;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .CategoryInformation .Name {
    font-size: 20px;
  }
  .CategoryInformation .itemcount {
    font-size: 20px;
  }
  .groupProductview {
    width: 90%;
  }
  .ProductScroll {
    width: 100%;
  }
}

.BrandCard_wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(200px, 200px))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  grid-gap: 20px;
  margin-top: 50px;
  border-radius: 15px;
  padding: 20px;
}

.CategoryPage {
  width: 80%;
  margin: 0 auto;
}

.BrandCard {
  margin: 0 auto;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  overflow: hidden;
}

.BrandCard:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.Letter {
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
  /* adjust this value to your header height */
  z-index: 0;
  width: 50px;
  text-align: center;
  color: #FFF;
  background-color: #CCC;
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.Letter.active {
  font-weight: bold;
  background-color: #359c8d;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  width: 250px;
}

.CustomerList {
  width: 90%;
  margin: 0 auto;
}

.customerinformation {
  width: 90%;
  margin: 0 auto;
}

.customerinformation .CryptoCard {
  background-color: #0a6887;
  margin: 0 auto;
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  font-family: 'Poppins', sans-serif;
}

.orderview {
  width: 90%;
  margin: 0 auto;
}

.orderview .CryptoCard {
  background-color: #0a6887;
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
}

.barcode-prices {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 15px;
}

.barcode-prices-price {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (2fr)[5] 1fr;
      grid-template-columns: repeat(5, 2fr) 1fr;
  padding: 10px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.barcode-prices-price-sub p {
  line-height: 100%;
}

.barcode-prices-price-sub p:first-of-type {
  font-size: 80%;
  margin-bottom: 10px;
}

.barcode-prices-price-sub p:last-of-type {
  font-weight: 500;
}

.barcode-prices-price-sub button {
  display: inline-block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 10px 10px 10px 12.5px;
  border-radius: 10px;
  background-color: whitesmoke;
}

.barcode-prices-price-sub button svg {
  fill: #0a6887;
}

.barcode-prices-price-sub:last-of-type {
  text-align: end;
}

@media screen and (min-width: 481px) {
  .users-view table td:nth-of-type(1) {
    width: 20%;
  }
  .users-view table td:nth-of-type(2) {
    width: 30%;
  }
  .users-view table td:nth-of-type(3) {
    width: 20%;
  }
  .users-view table td:nth-of-type(4) {
    width: 15%;
  }
}

.users-edit-form {
  position: relative;
}

.users-edit-form-contact {
  margin-top: 30px;
}

.users-edit-form #edit-role-form {
  margin-bottom: 50px;
}

.users-edit-form #edit-role-form .inline-btns {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

@media screen and (max-width: 480px) {
  .users-edit-form #edit-role-form select,
  .users-edit-form #edit-role-form input {
    width: 100%;
  }
}

.inbound-view .inline-btns {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
}

.inbound-details-top {
  margin: 30px 0;
}

.inbound-details-top p {
  font-size: 110%;
}

.inbound-details-top p strong {
  font-weight: 500;
}

.inbound-details-top p:not(:last-of-type) {
  margin-bottom: 10px;
}

@media screen and (min-width: 1366px) {
  .inbound-details-main {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }
}

@media screen and (max-width: 1365px) {
  .inbound-details-transactions {
    margin-bottom: 30px;
  }
}

.inbound-details-transactions-add {
  margin: 30px 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding: 25px 50px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #9A9A9A;
  color: #2b2b2b;
}

.inbound-details-transactions-add svg {
  fill: #2b2b2b;
  height: 20px;
  margin-right: 10px;
}

.inbound-details-transactions-add:disabled svg {
  fill: white;
}

.inbound-details-transactions-item {
  position: relative;
  width: 100%;
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
  padding: 25px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid #2b2b2b;
  color: #2b2b2b;
}

.inbound-details-transactions-item p strong {
  font-weight: 600;
}

.inbound-details-transactions-item p:not(:last-of-type) {
  margin-bottom: 5px;
}

.inbound-details-transactions-item button {
  position: absolute;
  top: 0%;
  right: 0%;
  -webkit-transform: translate(-75%, 55%);
          transform: translate(-75%, 55%);
  padding: 0;
}

.inbound-details-transactions-item button svg {
  height: 20px;
  width: auto;
  fill: #f85149;
}

.inbound-details-transactions-item button:hover {
  opacity: .9;
}

.inbound-details-cart-container {
  overflow-x: auto;
}

.inbound-details-cart table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
}

.inbound-details-cart table th {
  border-bottom: 1px solid #2b2b2b;
}

.inbound-details-cart table th {
  padding: 10px 10px;
}

.inbound-details-cart table td {
  padding: 7.5px 10px;
}

.receipt-modal {
  width: 80%;
  height: 80%;
}

.receipt-iframe {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  width: 3in;
  max-width: 3in;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
}

.receipt-iframe-wrapper {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
}

.receipt-iframe-container {
  overflow: hidden auto;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  max-height: 75%;
  width: 3in;
  max-width: 3in;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
}

.receipt-iframe-close {
  position: absolute;
  top: 2.5%;
  right: 0%;
}

.receipt-iframe-printbtn {
  position: absolute;
  top: 2.5%;
  left: 50%;
  translate: -50% 0;
  font-size: 125%;
  background-color: #0a6887;
  color: white;
  text-transform: uppercase;
}

.report {
  margin: 0 auto;
  width: 100%;
}

@media screen and (max-width: 1365px) {
  .report {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1366px) {
  .report {
    width: 100%;
  }
}

.report-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.report-large {
  width: 100%;
  min-height: 500px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 20px 30px;
  border-radius: 20px;
  overflow-x: auto;
  background-color: white;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
}

@media screen and (max-width: 480px) {
  .report-large {
    min-height: 300px;
    padding: 10px;
  }
}

@media screen and (min-width: 1366px) {
  .report-large {
    min-height: 500px;
  }
}

.report-large:last-of-type {
  margin-bottom: 100px;
}

.report-large.pie {
  padding: 30px;
}

@media screen and (max-width: 480px) {
  .report-large.pie {
    min-height: 200px;
  }
}

.report-small {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 4fr;
      grid-template-columns: 1fr 4fr;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 15px;
  padding: 20px 25px;
}

.report-small-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  -webkit-column-gap: 50px;
          column-gap: 50px;
  row-gap: 35px;
  margin-bottom: 50px;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .report-small-wrapper {
    margin-bottom: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    row-gap: 20px;
  }
}

@media screen and (min-width: 1366px) {
  .report-small-wrapper {
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .report-small {
    display: block;
  }
}

.report-small svg {
  height: 35px;
  width: auto;
  margin-right: 10px;
  fill: #0a6887;
}

@media screen and (max-width: 480px) {
  .report-small svg {
    height: 25px;
    display: none;
  }
}

.report-small-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  text-align: end;
  height: 100%;
}

.report-small-label {
  font-size: 90%;
  opacity: 80%;
  margin-bottom: 10px;
}

.report-small-value {
  font-weight: 500;
  font-size: 120%;
  line-height: 100%;
}

.report-small.outbound svg {
  fill: #f85149;
}

@media screen and (min-width: 1366px) {
  .report.home {
    width: 90%;
  }
}

.voucher_View_Row {
  width: 80%;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
  margin: 10px auto;
  border-bottom: grey 1px solid;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr .2fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr .2fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "Voucer_Date voucher_Id invoiceNumber   payee Paymentmode VoucherTotal";
}

.voucher_View_Row .voucher_Id {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: voucher_Id;
}

.voucher_View_Row .Voucer_Date {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: Voucer_Date;
}

.voucher_View_Row .VoucherTotal {
  -ms-grid-row: 1;
  -ms-grid-column: 6;
  grid-area: VoucherTotal;
  text-align: right;
}

.voucher_View_Row .Paymentmode {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
  grid-area: Paymentmode;
}

.voucher_View_Row .payee {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
  grid-area: payee;
}

.voucher_View_Row.verified {
  background-color: rgba(37, 158, 212, 0.3);
}

.voucher_View_Row.approved {
  background-color: rgba(53, 156, 141, 0.3);
}

.voucher_View_Row.declined {
  background-color: rgba(255, 0, 0, 0.281);
}

.voucherView {
  width: 100%;
  margin: 0 auto;
}

.voucherView .pagination-bar {
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  place-content: center;
}

.Voucher_view_singular {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: "interactions voucherdetails" "voucheritems voucheritems";
}

.Voucher_view_singular .interactions {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: interactions;
}

.Voucher_view_singular .voucherdetails {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: voucherdetails;
}

.Voucher_view_singular .voucheritems {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: voucheritems;
}

.printvoucher {
  margin: 0 auto;
  width: 100%;
  display: -ms-grid;
  display: grid;
}

.printvoucher.hidden {
  display: none;
}

.printvoucher .letterhead {
  margin: 20px;
  max-height: 100px;
}

.printvoucher .voucherbody .voucherdetails {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
  margin: 0 auto;
  width: 90%;
}

.printvoucher .voucherbody .voucherdetails ._field {
  font-size: 11px;
  font-weight: 800;
  margin: 5px 0;
  text-align: left;
}

.printvoucher .voucherbody .details {
  margin: 5px 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
}

.printvoucher .voucherbody .details .Title {
  font-weight: 800;
  border-bottom: 2px solid black;
  width: 80%;
  margin: 0 auto;
  font-size: 11px;
}

.printvoucher .voucherbody .details ._user {
  font-size: 10px;
  font-weight: 800;
}

.printvoucher .voucherbody .details ._Date {
  font-size: 10px;
}

.printvoucher .voucherbody .voucheritems_print {
  display: -ms-grid;
  display: grid;
  margin: 0 auto;
  width: 100%;
  font-size: 11px;
}

.printvoucher .voucherbody .voucheritems_print tbody {
  font-size: 11px;
}

.printvoucher .voucherbody .voucheritems_print tbody tr {
  border-bottom: 2px solid black;
}

.printvoucher .voucherbody .printtablevoucheritems {
  width: 95%;
  margin: 0 auto;
}

.footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.footer img {
  width: 80%;
  text-align: center;
  margin: 0 auto;
}
