.ApprovedItems{
   // background-color: rgb(44, 219, 111);

}
.StateName{
    color: $main-grey;
    font-size: 18px;
   // border-bottom: 2px solid $Primary-Theme-Green;
    padding: 5px;
    margin: 15px;

}
.Payment_Type_summery{
    color: $Primary-Blue;
    font-size: 18px;
    text-align: center;
    font-weight: 800;
    padding: 5px;
    text-decoration: underline;

}
.Payment_Type{
    color: $Primary-Theme-Green;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    border-bottom: 2px solid $Primary-Theme-Green;
    padding: 15px;

}
.Line_Item{
    border-bottom: 1px grey solid;
}
.ReadyToPack{
  //  background-color: rgb(180, 235, 92);

}

.InvoiceData{
    background-color: #e2e2e2;
   // background-color: #25bd97;
   // border-radius: 5px;
    margin : 10px auto;
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    

}

.Order_Items_Table{
    margin: 0 auto;
    width: 100%;
}
.Order_Items_Payment_mode{
    border-radius: 15px;
    box-shadow: $shadow;
    border: 3px solid $Primary;
    width: 90%;
    margin: 15px auto;

  }
.Order_Items_Credit_Period{
    //border-radius: 15px;
    box-shadow: $shadow;
  //  border: 1px solid $Primary;
  //  width: 90%;
    margin: 15px auto;

  }

.ReadyToDispatch{
   // background-color: rgb(92, 235, 147);

}
.DeclinedItems{
    //background-color: rgba(255, 0, 0, 0.322);
}
/*
.Line_Item{
    font-family: 'open sans';
    display: grid;
    background-color: rgb(255, 255, 255);
    width: 98%;
    grid-template-rows: 1fr  1fr ;
    grid-template-columns:.1fr 1fr 2fr .7fr .7fr;

   // border-radius: 5px;
   // border: 1px solid #359c8d36;
    margin: 0 auto;
    //padding: 5px;
    //font-weight: 600;
    font-size: 10px;
    grid-template-areas:
    " Name Name  Name  Name  Name "
    "List_Price Discount Price Qty  Amount";
    font-size: 9px;
    &_Brand{
       // border-radius: 5px;
       // box-shadow: $shadow;
    //  background-color: rgba(139, 7, 14, 0.144);
    //background-color: #63C7B2;
   // border:#73D884 solid 2px ;
    border:#40404052 solid 1px ;
    grid-area: Brand;
            
    }
    &_Supplier{
  //  background-color: rgba(132, 0, 255, 0.144);
  //border:#73D884 solid 2px ;
  border:#40404052 solid 1px ;
  grid-area: Supplier;    
        
    }
    &Line_Item_Checkbox{
        grid-area: Line_Item_Checkbox;
        display: grid;
        place-items: center;
        background-color: antiquewhite;
        margin: 0 auto;
    }
    &_approvalstate{
       // border-radius: 5px;
       // box-shadow: $shadow;
    grid-area: approvalstate;
    background-color: rgba(19, 130, 204, 0.548);
    margin: 0 auto;
    }

    &_Name{
       // border-radius: 5px;
       // box-shadow: $shadow;
    //background-color: rgba(255, 0, 13, 0.548);
   // background-color: #0a688775;
   // border:#0054fc solid 2px ;
    border:#40404052 solid 1px ;

    grid-area: Name;
    }

    &_ACode{
       // border-radius: 5px;
       // box-shadow: $shadow;
    grid-area: ACode;
    background-color: rgb(0, 17, 255);
    }

    &_Qty{
       // border-radius: 5px;
       // box-shadow: $shadow;
    grid-area: Qty;
 //   background-color: rgba(255, 0, 85, 0.144);
  //border:#ff20ff solid 2px ;
 border:#40404052 solid 1px ;
 text-align: right;
    padding: 2px 0;
    }

    &_List_Price{
       // border-radius: 5px;
       // box-shadow: $shadow;
      //   background-color: rgba(255, 153, 0, 0.733);
   // background-color: #8E6C88;
 //border:#825D5D solid 2px ;
 border:#40404052 solid 1px ;

    text-align: right;
    grid-area: List_Price;
    }

    &_Discount{
       // border-radius: 5px;
       // box-shadow: $shadow;
    grid-area: Discount;
    //  background-color: rgba(204, 169, 12, 0.144);
    //background-color: #80CED7;
 //border:#00bdfc solid 2px ;
 border:#40404052 solid 1px ;

    text-align: right;
    padding: 2px 0;
    }

    &_Profitablity{
       // border-radius: 5px;
       // box-shadow: $shadow;
    grid-area: Profitablity;
 //border:rgb(51, 255, 0) solid 2px ;
 border:#40404052 solid 1px ;

   // background-color: rgba(41, 177, 7, 0.596);
    text-align: right;
    padding: 2px 0;
    }

    &_Price{
       // border-radius: 5px;
       // box-shadow: $shadow;
    grid-area: Price;
      //  background-color: rgba(113, 219, 14, 0.144);
     //  background-color: #CCDBDC;
    text-align: right;
 //border:#6D6D6D solid 2px ;
 border:#40404052 solid 1px ;

 padding: 2px 0;
    }

    &_Amount{
       // border-radius: 5px;
       // box-shadow: $shadow;
    text-align: right;
    grid-area: Amount;
    padding: 2px 0;
  //  background-color: rgba(0, 255, 255, 0.144);
 //border:#005457 solid 2px ;
 border:#40404052 solid 1px ;

    }
    
    &_rowcount{
        grid-area: rowcount;
        text-align: right;
        padding: 2px 0;
    }
}*/
.Titles_Cart_Line_Item{
    font-family: 'open sans';

    display: grid;
    background-color: $Primary-Theme-Green;
    color: white;
    width: 98%;


    grid-template-rows: 1fr  1fr 1fr ;
    grid-template-columns:.1fr 1fr 2fr .7fr .7fr;

margin: 0 auto;
font-weight: 600;
font-size: 10px;
    grid-template-areas:
        " Name Name  Name  Name  ACode "
        " Supplier Supplier Supplier   Brand Brand  "
        "Qty Discount List_Price   Price   Amount"
        ;


        &_Brand{

            //border-left: 1px solid $Primary-Theme-Green;
                 //   background-color: rgba(139, 7, 14, 0.3);
              // background-color: #3aca4d;
                    grid-area: Titles_Brand;
                        
                    }
                &_Supplier{
               //    background-color: rgba(132, 0, 255, 0.3);
                    grid-area: Titles_Supplier;    
            //border-left: 1px solid $Primary-Theme-Green;
                    
                }
               
                 margin: 0 auto;
            
                 &_Name{
            //border-left: 1px solid $Primary-Theme-Green;
            
                   //    background-color: rgba(255, 0, 13, 0.3);
                  //   background-color: #0a688775;
                    grid-area: Titles_Name;
            }
            
                &_ACode{
            //border-left: 1px solid $Primary-Theme-Green;
            
                    grid-area: Titles_ACode;
                  //  background-color: rgba(61, 71, 207, 0.3);
            }
            
                &_Qty{
                    grid-area: Titles_Qty;
                  //    background-color: rgba(230, 197, 10, 0.3);
                    text-align: right;
                    padding: 2px 0;
            //border-left: 1px solid $Primary-Theme-Green;
                    
                }
            
                &_List_Price{
                   //   background-color: rgba(255, 153, 0, 0.3);
                  //   background-color: #e616c023;
                    text-align: right;
                    grid-area: Titles_List_Price;
            //border-left: 1px solid $Primary-Theme-Green;
                    
            }
                &_Discount{
                    grid-area: Titles_Discount;
                  //    background-color: rgba(204, 169, 12, 0.3);
                  //   background-color: #80ced77c;
                    text-align: right;
                    padding: 2px 0;
            //border-left: 1px solid $Primary-Theme-Green;
            
            }
            
            &_Price{
                    grid-area: Titles_Price;
                 //  background-color: rgba(14, 171, 219, 0.3);
                  //  background-color: #25bd97;
                    text-align: right;
                    padding: 2px 0;
            //border-left: 1px solid $Primary-Theme-Green;
            
                
            }
            .Titles_Cart_Line_Remove{
                text-align: center;
                grid-area: Titles_Remove;

                //      background-color: rgba(43, 255, 0, 0.3);
        // border-left: 1px solid $Primary-Theme-Green;
        
            }
                &_Amount{
                    text-align: right;
                    grid-area: Titles_Amount;
                    padding: 2px 0;
                 //      background-color: rgba(43, 255, 0, 0.3);
            // border-left: 1px solid $Primary-Theme-Green;
            
                }


}
.Cart_Line_Item{

   
    display: grid;
    
    width: 98%;


    grid-template-rows: 1fr  1fr 1fr ;
    grid-template-columns:.1fr 1fr 2fr .7fr .7fr;

//border-radius: 5px;
//border: 3px solid #359c8d36;
margin: 0 auto;
//padding: 5px;
font-weight: 600;
font-size: 10px;
    grid-template-areas:
        " Name Name  Name  Name  ACode "
        " Supplier Supplier Supplier   Brand   Brand"
        "Qty Discount List_Price   Price   Amount"
        ;


        font-size: 9px;

        &_Brand{
//border-left: 1px solid $Primary-Theme-Green;
    //   background-color: rgba(139, 7, 14, 0.144);
  // background-color: #3aca4d;
        grid-area: Brand;
            
        }
    &_Supplier{
     // background-color: rgba(132, 0, 255, 0.144);
        grid-area: Supplier;    
//border-left: 1px solid $Primary-Theme-Green;
        
    }
   
     margin: 0 auto;

     &_Name{
//border-left: 1px solid $Primary-Theme-Green;

        // background-color: rgba(255, 0, 13, 0.548);
      //   background-color: #0a688775;
        grid-area: Name;
}

    &_ACode{
//border-left: 1px solid $Primary-Theme-Green;

        grid-area: ACode;
      //  background-color: rgba(61, 71, 207, 0.767);
}

    &_Qty{
        grid-area: Qty;
       // background-color: rgba(230, 197, 10, 0.5);
        text-align: right;
        padding: 2px 0;
//border-left: 1px solid $Primary-Theme-Green;
        
    }

    &_List_Price{
      //   background-color: rgba(255, 153, 0, 0.733);
      //   background-color: #e616c023;
        text-align: right;
        grid-area: List_Price;
//border-left: 1px solid $Primary-Theme-Green;
        
}
    &_Discount{
        grid-area: Discount;
       //  background-color: rgba(204, 169, 12, 0.144);
      //   background-color: #80ced77c;
        text-align: right;
        padding: 2px 0;
//border-left: 1px solid $Primary-Theme-Green;

}

&_Price{
        grid-area: Price;
    // background-color: rgba(14, 171, 219, 0.3);
      //  background-color: #25bd97;
        text-align: right;
        padding: 2px 0;
//border-left: 1px solid $Primary-Theme-Green;

    
}
    &_Amount{
        text-align: right;
        grid-area: Amount;
        padding: 2px 0;
     //    background-color: rgba(43, 255, 0, 0.3);
// border-left: 1px solid $Primary-Theme-Green;

    }
   
}
   
//media query larger than 700 px 
@media screen and (min-width: 700px) {
.Line_Item{
    grid-template-rows: 1fr   ;
    grid-template-columns:.1fr    3fr 1fr .5fr 1fr 1fr 1fr .5fr minmax(250px,1fr)   ;
   font-size: 17px;
    grid-template-areas:
        "rowcount Name Brand  Qty List_Price  Discount Price  Amount Profitablity approvalstate"
       ;

     
}

.Titles_Cart_Line_Item{
    grid-template-rows: 1fr   ;
    grid-template-columns: 2fr   1fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr  1fr ;
   font-size: 17px;
    grid-template-areas:
        "Titles_Supplier Titles_ACode Titles_Name Titles_Brand Titles_Qty Titles_List_Price Titles_Discount Titles_Price Titles_Amount Titles_Remove"
       ;
     
}
.Cart_Line_Item{
    grid-template-rows: 1fr   ;
    grid-template-columns: 2fr   1fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr   ;
   font-size: 17px;
    grid-template-areas:
        "Supplier ACode Name Brand Qty List_Price Discount Price Amount"
       ;
     
}
}


.line-product-summary-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .line-product-summary-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .line-product-summary-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  